import { getContractAddressesForChainOrThrow } from '@0x/contract-addresses';
import { NULL_ADDRESS } from 'utils/constants';

export type OrderWrapper = {
  limitOrder: LimitOrderBI;
  signature: Signature;
  // This value is only set when order is fetched from backend
  ratio?: number;
};

export type ModifiedOrderWrapper = OrderWrapper & {
  takerAssetAmount: bigint;
  makerAssetAmount: bigint;
  salt: bigint;
};

export type ModifiedOrder = {
  order: ModifiedOrderWrapper;
  valuation: number;
  yield: number;
  selected: boolean;
};

export type ModifiedOrders = Array<ModifiedOrder>;

export type OrderState = {
  orderInfo: OrderInfoBI;
  remainingFillableAmount: bigint;
  isValidSignature: boolean;
};

// https://github.com/microsoft/TypeScript/issues/37783
// BigInt currently not supported in enum
export enum OrderStatusBI {
  Invalid = 0,
  Fillable = 1,
  Filled = 2,
  Cancelled = 3,
  Expired = 4,
}

export interface OrderInfoBI {
  status: OrderStatusBI;
  orderHash: string;
  takerTokenFilledAmount: bigint;
}

/**
 * Valid signature types on the Exchange Proxy.
 */
export enum SignatureType {
  Illegal = 0,
  Invalid = 1,
  EIP712 = 2,
  EthSign = 3,
  PreSigned = 4,
}
/**
 * Represents a raw EC signature.
 */
export interface ECSignature {
  v: number;
  r: string;
  s: string;
}
/**
 * A complete signature on the Exchange Proxy.
 */
export interface Signature extends ECSignature {
  signatureType: SignatureType;
}

const LIMIT_ORDER_BI_DEFAULT_VALUES = {
  takerTokenFeeAmount: 0n,
  sender: NULL_ADDRESS,
  feeRecipient: NULL_ADDRESS,
  expiry: 0n,
  pool: '0x0000000000000000000000000000000000000000000000000000000000000000',
  salt: 0n,
  makerToken: NULL_ADDRESS,
  takerToken: NULL_ADDRESS,
  makerAmount: 0n,
  takerAmount: 0n,
  maker: NULL_ADDRESS,
  taker: NULL_ADDRESS,
  chainId: 1,
  verifyingContract: getContractAddressesForChainOrThrow(1).exchangeProxy,
};

type LimitOrderBIFields = {
  takerTokenFeeAmount: bigint;
  sender: string;
  feeRecipient: string;
  expiry: bigint;
  pool: string;
  salt: bigint;
  makerToken: string;
  takerToken: string;
  makerAmount: bigint;
  takerAmount: bigint;
  maker: string;
  taker: string;
  chainId: number;
  verifyingContract: string;
};

export const LimitOrderEIP712Domain = [
  { type: 'address', name: 'makerToken' },
  { type: 'address', name: 'takerToken' },
  { type: 'uint128', name: 'makerAmount' },
  { type: 'uint128', name: 'takerAmount' },
  { type: 'uint128', name: 'takerTokenFeeAmount' },
  { type: 'address', name: 'maker' },
  { type: 'address', name: 'taker' },
  { type: 'address', name: 'sender' },
  { type: 'address', name: 'feeRecipient' },
  { type: 'bytes32', name: 'pool' },
  { type: 'uint64', name: 'expiry' },
  { type: 'uint256', name: 'salt' },
];

export class LimitOrderBI {
  constructor(fields?: Partial<LimitOrderBIFields>) {
    const _fields = Object.assign(Object.assign({}, LIMIT_ORDER_BI_DEFAULT_VALUES), fields);

    this.makerToken = _fields.makerToken;
    this.takerToken = _fields.takerToken;
    this.makerAmount = _fields.makerAmount;
    this.takerAmount = _fields.takerAmount;
    this.maker = _fields.maker;
    this.taker = _fields.taker;
    this.chainId = _fields.chainId;
    this.verifyingContract = _fields.verifyingContract;
    this.salt = _fields.salt;
    this.expiry = _fields.expiry;
    this.takerTokenFeeAmount = _fields.takerTokenFeeAmount;
    this.sender = _fields.sender;
    this.pool = _fields.pool;
    this.feeRecipient = _fields.feeRecipient;
  }

  getMessage() {
    return {
      makerToken: this.makerToken,
      takerToken: this.takerToken,
      makerAmount: this.makerAmount.toString(10),
      takerAmount: this.takerAmount.toString(10),
      takerTokenFeeAmount: this.takerTokenFeeAmount.toString(10),
      maker: this.maker,
      taker: this.taker,
      sender: this.sender,
      feeRecipient: this.feeRecipient,
      pool: this.pool,
      expiry: this.expiry.toString(10),
      salt: this.salt.toString(10),
    };
  }

  takerTokenFeeAmount: bigint;
  sender: string;
  feeRecipient: string;
  expiry: bigint;
  pool: string;
  salt: bigint;
  makerToken: string;
  takerToken: string;
  makerAmount: bigint;
  takerAmount: bigint;
  maker: string;
  taker: string;
  chainId: number;
  verifyingContract: string;
}
