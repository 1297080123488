import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  createNotification,
  createNotificationByType,
  NOTIFICATION_TYPES,
} from 'utils/notification';
import i18n from 'i18n';
import API from 'utils/api';
import { gqlclient, GET_PROPERTY, GET_PROPERTIES } from 'query';
import { GET_OFFERING, GET_OFFERINGS } from 'query/offering';
import { RESET_STATE } from './sharedActions';
import type {
  OnChainOfferingRaw,
  OnChainOfferings,
  OnChainOfferingsRaw,
  OnChainProperties,
  OnChainPropertiesRaw,
  OnChainPropertyRaw,
  PropertiesState,
} from 'types';
import { OnChainOffering, OnChainProperty } from 'types';

const initialState: PropertiesState = {
  documents: [],
  statistics: {
    capitalStacks: [],
    revenueDistributions: [],
    tokenBalances: [],
    valuation: 0,
  },
  featuredProperties: [],
  featuredPropertiesAverageMonthlyPayouts: [],
  properties: [],
  onChainProperties: [],
  onChainOfferings: [],
  isRequestingOnChainProperties: false,
  isRequestingOnChainOfferings: false,
  onChainPropertiesRequestError: null,
  onChainOfferingsRequestError: null,
  offeringProperties: [],
  property: {
    address: '',
    featuredImage: '',
    images: [],
    highlights: [],
    latitude: 0,
    longitude: 0,
    offeringPrice: 0,
    propertyName: '',
    token: {
      address: '',
      created: '',
      numberOfDecimals: 0,
      currentValuation: 0,
      symbol: '',
      totalSupply: 0,
    },
    valuation: 0,
    status: '',
    propertyStatistic: {
      availableTokens: 0,
      averageRevenue: 0,
      profitDistributed: 0,
      projectedYield: 0,
      propertyValuation: 0,
      tokenValuation: 0,
      valuation: 0,
      averageYearlyRevenue: 0,
    },
  },
  issuer: {
    address: '',
    latitude: 0,
    longitude: 0,
    stats: {
      averageHolder: 0,
      averageYield: 0,
      listedProperties: 0,
      profitDistributed: 0,
      totalInvestors: 0,
      totalValuation: 0,
    },
    name: '',
    walletAddress: '',
    websiteUrl: '',
    isRequestingIssuer: false,
    errorMessage: null,
  },
  trades: [],
  isRequestingFeaturedProperties: false,
  errorMessageFeaturedProperties: null,
  isRequestingOfferingProperties: false,
  errorMessageOfferingProperties: null,
  isRequestingProperties: false,
  errorMessageProperties: null,
  isRequestingProperty: false,
  errorMessageProperty: null,
  isRequestingDocuments: false,
  errorMessageDocuments: null,
  isRequestingStatistics: false,
  errorMessageStatistics: null,
  isRequestingTrades: false,
  errorMessageTrades: null,
  isRequestingOnChainProperty: false,
  errorMessageRequestOnChainProperty: null,
  isRequestingOnChainOffering: false,
  errorMessageRequestOnChainOffering: null,
};

const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    featuredPropertiesFetchRequest(state) {
      state.isRequestingFeaturedProperties = true;
    },
    featuredPropertiesFetchSuccess(state, action) {
      state.isRequestingFeaturedProperties = false;
      state.featuredProperties = action.payload;
      state.errorMessageFeaturedProperties = null;
    },
    featuredPropertiesFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingFeaturedProperties = false;
      state.errorMessageFeaturedProperties = reason;
    },
    featuredPropertiesSetAverageMonthlyPayout(state, action) {
      const { averageMonthlyPayouts } = action.payload;
      if (averageMonthlyPayouts.length >= 1) {
        state.featuredPropertiesAverageMonthlyPayouts = averageMonthlyPayouts;
      }
    },
    offeringPropertiesFetchRequest(state) {
      state.isRequestingOfferingProperties = true;
    },
    offeringPropertiesFetchSuccess(state, action) {
      state.offeringProperties = action.payload;
      state.isRequestingOfferingProperties = false;
      state.errorMessageOfferingProperties = null;
    },
    offeringPropertiesFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingOfferingProperties = false;
      state.errorMessageOfferingProperties = reason;
    },
    propertiesFetchRequest(state) {
      state.isRequestingProperties = true;
    },
    propertiesFetchSuccess(state, action) {
      state.properties = action.payload;
      state.isRequestingProperties = false;
      state.errorMessageProperties = null;
    },
    propertiesFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingProperties = false;
      state.errorMessageProperties = reason;
    },
    propertyFetchRequest(state) {
      state.isRequestingProperty = true;
    },
    propertyFetchSuccess(state, action) {
      state.isRequestingProperty = false;
      state.errorMessageProperty = null;
      const {
        address,
        images,
        highlights,
        latitude,
        longitude,
        offeringPrice,
        propertyName,
        token,
        status,
        propertyStatistic,
        valuation,
        featuredImage,
      } = action.payload;

      state.property.address = address;
      state.property.featuredImage = featuredImage;
      state.property.images = images;
      state.property.highlights = Object.entries(highlights).map(([key, value]) => ({
        id: key.toLowerCase().replace(' ', '_'),
        type: key,
        text: value,
      }));
      state.property.latitude = latitude;
      state.property.longitude = longitude;
      state.property.offeringPrice = offeringPrice;
      state.property.status = status;
      state.property.propertyName = propertyName;
      state.property.token.address = token.address;
      state.property.token.created = token.created;
      state.property.token.numberOfDecimals = token.numberOfDecimals;
      state.property.token.currentValuation = token.currentValuation || 0;
      state.property.token.totalSupply = token.totalSupply;
      state.property.token.symbol = token.symbol;
      state.property.propertyStatistic.availableTokens = propertyStatistic.availableTokens;
      state.property.propertyStatistic.averageRevenue = propertyStatistic.averageRevenue;
      state.property.propertyStatistic.profitDistributed = propertyStatistic.profitDistributed;
      state.property.propertyStatistic.projectedYield = propertyStatistic.projectedYield;
      state.property.propertyStatistic.propertyValuation = propertyStatistic.propertyValuation;
      state.property.propertyStatistic.tokenValuation = propertyStatistic.tokenValuation;
      state.property.propertyStatistic.valuation = propertyStatistic.valuation;
      state.property.propertyStatistic.averageYearlyRevenue =
        propertyStatistic.averageYearlyRevenue;
      state.property.valuation = valuation;
    },
    propertyFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingProperty = false;
      state.errorMessageProperty = reason;
    },
    documentsFetchRequest(state) {
      state.isRequestingDocuments = true;
    },
    documentsFetchSuccess(state, action) {
      state.isRequestingDocuments = false;
      state.documents = action.payload;
      state.errorMessageDocuments = null;
    },
    documentsFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingDocuments = false;
      state.errorMessageDocuments = reason;
    },
    statisticsFetchRequest(state) {
      state.isRequestingStatistics = true;
    },
    statisticsFetchSuccess(state, action) {
      state.isRequestingStatistics = false;
      const { capitalStacks, revenueDistributions, tokenBalances, valuation } = action.payload;
      state.statistics.capitalStacks = capitalStacks;
      // Sort revenues by oldest -> newest
      state.statistics.revenueDistributions = revenueDistributions.sort((a, b) =>
        a.date > b.date ? 1 : -1
      );
      state.statistics.tokenBalances = tokenBalances;
      state.statistics.valuation = valuation;
      state.errorMessageStatistics = null;
    },
    statisticsFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingStatistics = false;
      state.errorMessageStatistics = reason;
    },
    tradesFetchRequest(state) {
      state.isRequestingTrades = true;
    },
    tradesFetchSuccess(state, action) {
      state.isRequestingTrades = true;
      state.trades = action.payload;
      state.errorMessageTrades = null;
    },
    tradesFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingTrades = true;
      state.errorMessageTrades = reason;
    },
    issuerFetchRequest(state) {
      state.issuer.isRequestingIssuer = true;
    },
    issuerFetchSuccess(state, action) {
      state.issuer.isRequestingIssuer = false;
      state.issuer.errorMessage = null;
      const {
        address,
        latitude,
        longitude,
        licencedIssuerStats: {
          averageHolder,
          averageYield,
          listedProperties,
          profitDistributed,
          totalInvestors,
          totalValuation,
        },
        name,
        walletAddress,
        websiteUrl,
      } = action.payload;
      state.issuer.address = address;
      state.issuer.latitude = latitude;
      state.issuer.longitude = longitude;
      state.issuer.stats.averageHolder = averageHolder;
      state.issuer.stats.averageYield = averageYield;
      state.issuer.stats.listedProperties = listedProperties;
      state.issuer.stats.profitDistributed = profitDistributed;
      state.issuer.stats.totalInvestors = totalInvestors;
      state.issuer.stats.totalValuation = totalValuation;
      state.issuer.name = name;
      state.issuer.walletAddress = walletAddress;
      state.issuer.websiteUrl = websiteUrl;
    },
    issuerFetchFailure(state, action) {
      const { reason } = action.payload;
      state.issuer.isRequestingIssuer = false;
      state.issuer.errorMessage = reason;
    },
    propertyOnChainFetchRequest(state) {
      state.isRequestingOnChainProperty = true;
    },
    propertyOnChainFetchSuccess(state, { payload }: PayloadAction<OnChainProperty>) {
      // We need to copy the array first, update it and then set the full array as new state.
      // Otherwise the state is not successful updated.
      const newOnChainProperties = { ...state.onChainProperties };
      newOnChainProperties[payload.id] = payload;
      state.onChainProperties = newOnChainProperties;
      state.isRequestingOnChainProperty = false;
    },
    propertyOnChainFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingOnChainProperty = false;
      state.errorMessageRequestOnChainProperty = reason;
    },
    propertiesOnChainFetchRequest(state) {
      state.isRequestingOnChainProperties = true;
    },
    propertiesOnChainFetchSuccess(state, { payload }: PayloadAction<OnChainProperties>) {
      state.onChainProperties = payload;
      state.isRequestingOnChainProperties = false;
    },
    propertiesOnChainFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingOnChainProperties = false;
      state.onChainPropertiesRequestError = reason;
    },
    offeringOnChainFetchRequest(state) {
      state.isRequestingOnChainOffering = true;
    },
    offeringOnChainFetchSuccess(state, { payload }: PayloadAction<OnChainOffering>) {
      state.onChainOfferings[payload.id] = payload;
      state.isRequestingOnChainOffering = false;
    },
    offeringOnChainFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingOnChainOffering = false;
      state.errorMessageRequestOnChainOffering = reason;
    },
    offeringsOnChainFetchRequest(state) {
      state.isRequestingOnChainOfferings = true;
    },
    offeringsOnChainFetchSuccess(state, { payload }: PayloadAction<OnChainOfferings>) {
      state.onChainOfferings = payload;
      state.isRequestingOnChainOfferings = false;
    },
    offeringsOnChainFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingOnChainOfferings = false;
      state.onChainOfferingsRequestError = reason;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function getPropertiesRequest() {
  return async (dispatch, getState) => {
    dispatch(propertiesFetchRequest());
    return API.get('/property', {
      params: {
        type: 'MARKETPLACE',
      },
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        dispatch(propertiesFetchSuccess(data));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(propertiesFetchFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function propertyDetailsFetchRequest(propertyAddress) {
  return async (dispatch, getState) => {
    const state = getState();
    const { properties } = state;
    if (properties.property.token.address !== propertyAddress) {
      dispatch(getPropertyOnChain(propertyAddress));
      dispatch(propertyFetchRequest());
      API.get(`/property/${propertyAddress}`, {
        headers: {
          Authorization: `Bearer ${getState().authentication.token}`,
        },
      })
        .then((response) => {
          const { data } = response;
          if (!data) {
            const reason = i18n.t('property_not_found');
            dispatch(createNotification(reason, 'error'));
            dispatch(propertyFetchFailure({ reason }));
            return;
          }
          dispatch(propertyFetchSuccess(data));
        })
        .catch(() => {
          const networkError = i18n.t('network_error');
          dispatch(propertyFetchFailure({ reason: networkError }));
          dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        });
    }
  };
}

export function propertyDocumentsFetchRequest(propertyAddress) {
  return async (dispatch, getState) => {
    dispatch(documentsFetchRequest());
    return API.get(`/property/${propertyAddress}/documents`, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        dispatch(documentsFetchSuccess(data));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(documentsFetchFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function propertyStatisticsFetchRequest(propertyAddress) {
  return async (dispatch, getState) => {
    dispatch(statisticsFetchRequest());
    return API.get(`/property/${propertyAddress}/statistics`, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        dispatch(statisticsFetchSuccess(data));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(statisticsFetchFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function getFeaturedPropertiesRequest() {
  return async (dispatch, getState) => {
    dispatch(featuredPropertiesFetchRequest());
    return API.get('/property', {
      params: {
        type: 'FEATURED',
      },
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        dispatch(featuredPropertiesFetchSuccess(data));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(featuredPropertiesFetchFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function setFeaturedPropertiesAvgMonthlyPayout(averageMonthlyPayouts) {
  return async (dispatch) => {
    dispatch(featuredPropertiesSetAverageMonthlyPayout({ averageMonthlyPayouts }));
  };
}

export function getOfferingPropertiesRequest() {
  return async (dispatch, getState) => {
    dispatch(offeringPropertiesFetchRequest());
    return API.get('/property', {
      params: {
        type: 'OFFERING',
      },
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        dispatch(offeringPropertiesFetchSuccess(data));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(offeringPropertiesFetchFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function propertyIssuerFetchRequest(propertyAddress) {
  return async (dispatch, getState) => {
    dispatch(issuerFetchRequest());
    API.get(`/property/${propertyAddress}/licenced-issuer`, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        dispatch(issuerFetchSuccess(data));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(issuerFetchFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function getPropertyOnChain(tokenAddress) {
  return async (dispatch) => {
    dispatch(propertyOnChainFetchRequest());
    return gqlclient
      .query<{ property: OnChainPropertyRaw | null }>({
        query: GET_PROPERTY,
        variables: {
          propertyId: tokenAddress.toLowerCase(),
        },
      })
      .then((result) => {
        const {
          data: { property: propertyRaw },
        } = result;
        if (propertyRaw) {
          const onChainProperty = new OnChainProperty(propertyRaw);
          dispatch(propertyOnChainFetchSuccess(onChainProperty));
        }
      })
      .catch((error) => {
        dispatch(propertyOnChainFetchFailure({ reason: error.message }));
        dispatch(createNotification(error.message, 'error'));
      });
  };
}

export function getPropertiesOnChain() {
  return async (dispatch) => {
    dispatch(propertiesOnChainFetchRequest());
    return gqlclient
      .query<OnChainPropertiesRaw>({
        query: GET_PROPERTIES,
      })
      .then((result) => {
        const {
          data: { properties: propertiesRaw },
        } = result;

        const properties: OnChainProperties = [];
        if (propertiesRaw) {
          propertiesRaw.forEach((propertyRaw) => {
            properties[propertyRaw.id] = new OnChainProperty(propertyRaw);
          });
        }
        dispatch(propertiesOnChainFetchSuccess(properties));
      })
      .catch((error) => {
        dispatch(propertiesOnChainFetchFailure({ reason: error.message }));
        dispatch(createNotification(error.message, 'error'));
      });
  };
}

export function getOfferingOnChain(tokenAddress) {
  return async (dispatch) => {
    dispatch(offeringOnChainFetchRequest());
    return gqlclient
      .query<{ propertyOffering: OnChainOfferingRaw }>({
        query: GET_OFFERING,
        variables: {
          offeringId: tokenAddress.toLowerCase(),
        },
      })
      .then((result) => {
        const {
          data: { propertyOffering: offering },
        } = result;
        if (offering) {
          dispatch(offeringOnChainFetchSuccess(new OnChainOffering(offering)));
        }
      })
      .catch((error) => {
        dispatch(offeringOnChainFetchFailure({ reason: error.message }));
        dispatch(createNotification(error.message, 'error'));
      });
  };
}

export function getOfferingsOnChain() {
  return async (dispatch) => {
    dispatch(offeringsOnChainFetchRequest());
    return gqlclient
      .query<OnChainOfferingsRaw>({
        query: GET_OFFERINGS,
      })
      .then((result) => {
        const {
          data: { propertyOfferings },
        } = result;

        const onChainOfferings: Array<OnChainOffering> = [];
        if (propertyOfferings) {
          propertyOfferings.forEach((offering) => {
            onChainOfferings[offering.id] = new OnChainOffering(offering);
          });
        }
        dispatch(offeringsOnChainFetchSuccess(onChainOfferings));
      })
      .catch((error) => {
        dispatch(offeringsOnChainFetchFailure({ reason: error.message }));
        dispatch(createNotification(error.message, 'error'));
      });
  };
}

export function checkPendingProForma(address) {
  return async (dispatch, getState) => {
    return API.get(`/initial-sale/investment/property/${address}?status=PAYED&status=PENDING`, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        if (data && data.length === 1 && data[0].transactionHash) {
          return data[0].transactionHash;
        }

        return false;
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(propertiesFetchFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));

        throw new Error('pending-pro-forma-check-failure');
      });
  };
}

export const {
  documentsFetchFailure,
  documentsFetchRequest,
  documentsFetchSuccess,
  featuredPropertiesFetchFailure,
  featuredPropertiesFetchRequest,
  featuredPropertiesFetchSuccess,
  featuredPropertiesSetAverageMonthlyPayout,
  issuerFetchFailure,
  issuerFetchRequest,
  issuerFetchSuccess,
  statisticsFetchRequest,
  statisticsFetchSuccess,
  statisticsFetchFailure,
  propertiesFetchFailure,
  propertiesFetchRequest,
  propertiesFetchSuccess,
  propertyFetchFailure,
  propertyFetchRequest,
  propertyFetchSuccess,
  offeringPropertiesFetchFailure,
  offeringPropertiesFetchRequest,
  offeringPropertiesFetchSuccess,
  propertyOnChainFetchRequest,
  propertyOnChainFetchSuccess,
  propertyOnChainFetchFailure,
  propertiesOnChainFetchRequest,
  propertiesOnChainFetchSuccess,
  propertiesOnChainFetchFailure,
  offeringOnChainFetchRequest,
  offeringOnChainFetchSuccess,
  offeringOnChainFetchFailure,
  offeringsOnChainFetchRequest,
  offeringsOnChainFetchSuccess,
  offeringsOnChainFetchFailure,
} = propertiesSlice.actions;
export default propertiesSlice.reducer;
