import { gql } from '@apollo/client';

export const GET_PROPERTY = gql`
  query Property($propertyId: String) {
    property(id: $propertyId) {
      id
      propertyValuation
      tokenValuation
      ipfs
      token {
        totalHolders
        totalSupply
      }
      capitalStacks {
        id
        timestamp
        cap
        commonEquity
        preferredEquity
        mezzanine
        juniorDebt
        seniorDebt
      }
      wallets(first: 10, orderBy: amount, orderDirection: "desc") {
        amount
        wallet {
          id
        }
      }
    }
  }
`;

export const GET_PROPERTIES = gql`
  query Properties {
    properties(first: 1000) {
      id
      propertyValuation
      tokenValuation
      ipfs
      token {
        totalHolders
        totalSupply
      }
      capitalStacks {
        id
        cap
      }
    }
  }
`;

export const GET_PROPERTY_USER = gql`
  query User($userId: String, $propertyId: String) {
    user(id: $userId) {
      wallets {
        properties(where: { property: { id: $propertyId } }) {
          amount
        }
      }
    }
  }
`;
