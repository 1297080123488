import { store } from 'store';

const isWalletRegistered = (address) => {
  const {
    userWallet: { wallets },
  } = store.getState();

  if (wallets.length > 0) {
    const wallet = wallets.find((w) => w.address.toLowerCase() === address.toLowerCase());
    if (wallet) return true;
  }
  return false;
};

const isWalletWhitelisted = (address) => {
  const {
    userWallet: { wallets },
  } = store.getState();

  if (wallets.length > 0) {
    const wallet = wallets.find((w) => w.address.toLowerCase() === address.toLowerCase());
    if (wallet && wallet.addressStatus === 'ON_BLOCKCHAIN') return true;
  }
  return false;
};

export { isWalletRegistered, isWalletWhitelisted };
