import { addNotification } from 'store/notification';
import i18n from 'i18n';

export const NOTIFICATION_TYPES = {
  BANK_ACCOUNT_SAVED: 0,
  NETWORK_ERROR: 1,
  ORDER_CREATION_ERROR: 2,
  ORDER_FILLED: 3,
  ORDER_PLACED: 4,
  PASSWORD_CHANGED: 5,
  WALLET_NAME_CHANGED: 6,
  PASSWORD_FORGET_REQUESTED: 7,
  SETTINGS_SAVED: 8,
};

export function createNotification(message, variant = 'success') {
  return addNotification({
    key: new Date().getTime() + Math.random(),
    message,
    options: {
      variant,
      autoHideDuration: 3000,
    },
  });
}

export function createNotificationByType(type) {
  let isError = false;
  let message = '';
  switch (type) {
    case NOTIFICATION_TYPES.BANK_ACCOUNT_SAVED:
      message = i18n.t('notification_bank_account_saved');
      break;
    case NOTIFICATION_TYPES.NETWORK_ERROR:
      isError = true;
      message = i18n.t('network_error');
      break;
    case NOTIFICATION_TYPES.ORDER_CREATION_ERROR:
      isError = true;
      message = i18n.t('notification_create_order_error');
      break;
    case NOTIFICATION_TYPES.ORDER_FILLED:
      message = i18n.t('notification_order_filled');
      break;
    case NOTIFICATION_TYPES.ORDER_PLACED:
      message = i18n.t('notification_order_placed');
      break;
    case NOTIFICATION_TYPES.PASSWORD_CHANGED:
      message = i18n.t('notification_password_changed');
      break;
    case NOTIFICATION_TYPES.WALLET_NAME_CHANGED:
      message = i18n.t('notification_wallet_name_changed');
      break;
    case NOTIFICATION_TYPES.PASSWORD_FORGET_REQUESTED:
      message = i18n.t('notification_password_reset_request_success');
      break;
    case NOTIFICATION_TYPES.SETTINGS_SAVED:
      message = i18n.t('notification_settings_saved');
      break;
    default:
      throw new Error('Invalid TYPE for notification');
  }

  return addNotification({
    key: new Date().getTime() + Math.random(),
    message,
    options: {
      variant: isError ? 'error' : 'success',
      autoHideDuration: 3000,
    },
  });
}
