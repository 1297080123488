/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import { hideDialogs } from 'store/dialogs';
import API, { STATUS_SUCCESS } from 'utils/api';
import { createNotificationByType, NOTIFICATION_TYPES } from 'utils/notification';
import i18n from 'i18n';
import { RESET_STATE } from './sharedActions';

const initialState = {
  bankAccountType: '',
  bankAddress: '',
  bankBic: '',
  bankName: '',
  country: '',
  fetchError: null,
  iban: '',
  isFetching: false,
  isInitialFetchingDone: false,
  isInitialState: true,
  isUpdating: false,
  name: '',
  updateErrorMessage: null,
};

const userBankAccountSlice = createSlice({
  name: 'userBankAccount',
  initialState,
  reducers: {
    bankAccountFetchRequest(state) {
      state.isFetching = true;
    },
    bankAccountFetchSuccess(state, action) {
      state.fetchError = null;
      state.isFetching = false;
      state.isInitialFetchingDone = true;
      if (action.payload != null) {
        const { bankAccountType, bankAddress, bankBIC, bankName, country, iban, name } =
          action.payload;
        state.bankAccountType = bankAccountType;
        state.bankAddress = bankAddress;
        state.bankBic = bankBIC;
        state.bankName = bankName;
        state.country = country;
        state.iban = iban;
        state.name = name;
      }
      state.isInitialState = false;
    },
    bankAccountFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isFetching = false;
      state.isInitialFetchingDone = true;
      state.fetchError = reason;
    },
    bankAccountUpdateRequest(state) {
      state.isUpdating = true;
    },
    bankAccountUpdateSuccess(state) {
      state.isUpdating = false;
      state.updateErrorMessage = null;
    },
    bankAccountUpdateFailure(state, action) {
      const { error } = action.payload;
      state.isUpdating = false;
      state.updateErrorMessage = error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function userBankAccountFetchRequest(refresh = false) {
  return async (dispatch, getState) => {
    const state = getState();
    const { userBankAccount } = state;
    if (userBankAccount.isInitialState || refresh) {
      dispatch(bankAccountFetchRequest());
      return API.get('/account/bank', {
        headers: {
          Authorization: `Bearer ${getState().authentication.token}`,
        },
      })
        .then((response) => {
          const { data } = response;
          dispatch(bankAccountFetchSuccess(data));
        })
        .catch(() => {
          const networkError = i18n.t('network_error');
          dispatch(bankAccountFetchFailure({ reason: networkError }));
          dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        });
    }
    return null;
  };
}

export function userUpdateBankAccount(bankAccountData) {
  return async (dispatch, getState) => {
    dispatch(bankAccountUpdateRequest());
    return API.post('/account/bank', bankAccountData, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        const { status } = data;

        if (data.status === STATUS_SUCCESS) {
          dispatch(bankAccountUpdateSuccess());
          dispatch(userBankAccountFetchRequest(true));
          dispatch(createNotificationByType(NOTIFICATION_TYPES.BANK_ACCOUNT_SAVED));
          dispatch(hideDialogs());
        } else {
          dispatch(bankAccountUpdateFailure(status));
          dispatch(userBankAccountFetchRequest(true));
        }
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(bankAccountUpdateFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}
export const {
  bankAccountFetchFailure,
  bankAccountFetchRequest,
  bankAccountFetchSuccess,
  bankAccountUpdateFailure,
  bankAccountUpdateRequest,
  bankAccountUpdateSuccess,
} = userBankAccountSlice.actions;
export default userBankAccountSlice.reducer;
