type CapitalStackRaw = {
  cap: string;
  commonEquity: string;
  id: string;
  juniorDebt: string;
  mezzanine: string;
  preferredEquity: string;
  seniorDebt: string;
  timestamp: number;
};

type WalletRaw = {
  amount: string;
  wallet: {
    id: string;
  };
};

type Wallet = {
  amount: bigint;
  id: string;
};

type TokenRaw = {
  name: string;
  symbol: string;
  totalHolders: number;
  totalSupply: string;
};

export type OnChainOfferingRaw = {
  id: string;
  maxInvestment: string;
  minInvestment: string;
  presaleMaxInvestment: string;
  presaleMinInvestment: string;
  presaleStart: number;
  presaleEnd: number;
  saleStart: number;
  saleEnd: number;
  price: string;
  property: {
    token: TokenRaw;
    capitalStacks: Array<CapitalStackRaw>;
  };
};

export type OnChainOfferingsRaw = {
  propertyOfferings: Array<OnChainOfferingRaw> | null;
};

export type OnChainPropertyRaw = {
  capitalStacks: Array<CapitalStackRaw>;
  wallets: Array<WalletRaw>;
  id: string;
  ipfs: string;
  propertyValuation: string;
  token: TokenRaw;
  tokenValuation: string;
};

export type OnChainPropertiesRaw = {
  properties: Array<OnChainPropertyRaw> | null;
};

export class Token {
  constructor(tokenRaw: TokenRaw) {
    this.name = tokenRaw?.name ?? '';
    this.symbol = tokenRaw?.symbol ?? '';
    this.totalSupply = BigInt(tokenRaw?.totalSupply ?? 0);
    this.totalHolders = tokenRaw?.totalHolders ?? 0;
  }

  name: string;
  symbol: string;
  totalHolders: number;
  totalSupply: bigint;
}

export class CapitalStack {
  constructor(capitalStackRaw: CapitalStackRaw) {
    this.id = capitalStackRaw.id ?? '';
    this.cap = BigInt(capitalStackRaw.cap ?? 0);
    this.commonEquity = BigInt(capitalStackRaw.commonEquity ?? 0);
    this.preferredEquity = BigInt(capitalStackRaw.preferredEquity ?? 0);
    this.mezzanine = BigInt(capitalStackRaw.mezzanine ?? 0);
    this.juniorDebtBI = BigInt(capitalStackRaw.juniorDebt ?? 0);
    this.seniorDebt = BigInt(capitalStackRaw.seniorDebt ?? 0);
    this.timestamp = capitalStackRaw.timestamp ?? 0;
  }

  cap: bigint;
  commonEquity: bigint;
  id: string;
  juniorDebtBI: bigint;
  mezzanine: bigint;
  preferredEquity: bigint;
  seniorDebt: bigint;
  timestamp: number;
}

export class OnChainOffering {
  constructor(onChainOfferingRaw: OnChainOfferingRaw) {
    this.id = onChainOfferingRaw.id ?? '';
    this.maxInvestment = BigInt(onChainOfferingRaw.maxInvestment ?? 0);
    this.minInvestment = BigInt(onChainOfferingRaw.minInvestment ?? 0);
    this.presaleMaxInvestment = BigInt(onChainOfferingRaw.presaleMaxInvestment ?? 0);
    this.presaleMinInvestment = BigInt(onChainOfferingRaw.presaleMinInvestment ?? 0);
    this.presaleStart = onChainOfferingRaw.presaleStart ?? 0;
    this.presaleEnd = onChainOfferingRaw.presaleEnd ?? 0;
    this.saleStart = onChainOfferingRaw.saleStart ?? 0;
    this.saleEnd = onChainOfferingRaw.saleEnd ?? 0;
    this.price = onChainOfferingRaw.price ?? 0;
    this.property = {
      token: new Token(onChainOfferingRaw.property.token),
      capitalStacks:
        onChainOfferingRaw.property.capitalStacks?.map(
          (capitalStackRaw) => new CapitalStack(capitalStackRaw)
        ) ?? [],
    };
  }

  id: string;
  maxInvestment: bigint;
  minInvestment: bigint;
  presaleMaxInvestment: bigint;
  presaleMinInvestment: bigint;
  presaleStart: number;
  presaleEnd: number;
  saleStart: number;
  saleEnd: number;
  price: string;
  property: {
    token: Token;
    capitalStacks: Array<CapitalStack>;
  };
}

export type OnChainOfferings = Array<OnChainOffering>;

export class OnChainProperty {
  constructor(onChainPropertyRaw: OnChainPropertyRaw) {
    this.id = onChainPropertyRaw.id;
    this.ipfs = onChainPropertyRaw.ipfs;
    this.tokenValuation = BigInt(onChainPropertyRaw.tokenValuation ?? 0);
    this.propertyValuation = BigInt(onChainPropertyRaw.propertyValuation ?? 0);
    this.token = new Token(onChainPropertyRaw.token);
    this.capitalStacks =
      onChainPropertyRaw.capitalStacks?.map(
        (capitalStackRaw) => new CapitalStack(capitalStackRaw)
      ) ?? [];
    this.wallets =
      onChainPropertyRaw.wallets?.map((rawWallet) => {
        const id = rawWallet.wallet?.id ?? '';
        const amount = BigInt(rawWallet.amount ?? 0);
        return {
          amount,
          id,
        };
      }) ?? [];
  }
  id: string;
  capitalStacks: Array<CapitalStack>;
  ipfs: string;
  propertyValuation: bigint;
  token: Token;
  tokenValuation: bigint;
  wallets: Array<Wallet>;
}

export type OnChainProperties = Array<OnChainProperty>;

export type PropertiesState = {
  documents: Array<any>;
  statistics: {
    capitalStacks: Array<any>;
    revenueDistributions: Array<any>;
    tokenBalances: Array<any>;
    valuation: number;
  };
  featuredProperties: Array<any>;
  featuredPropertiesAverageMonthlyPayouts: Array<any>;
  properties: Array<any>;
  onChainProperties: OnChainProperties;
  onChainOfferings: Array<any>;
  isRequestingOnChainProperties: boolean;
  isRequestingOnChainOfferings: boolean;
  onChainPropertiesRequestError: null | string;
  onChainOfferingsRequestError: null | string;
  offeringProperties: Array<any>;
  property: {
    address: string;
    featuredImage: string;
    images: Array<string>;
    highlights: Array<any>;
    latitude: number;
    longitude: number;
    offeringPrice: number;
    propertyName: string;
    token: {
      address: string;
      created: string;
      numberOfDecimals: number;
      currentValuation: number;
      symbol: string;
      totalSupply: number;
    };
    valuation: number;
    status: string;
    propertyStatistic: {
      availableTokens: number;
      averageRevenue: number;
      profitDistributed: number;
      projectedYield: number;
      propertyValuation: number;
      tokenValuation: number;
      valuation: number;
      averageYearlyRevenue: number;
    };
  };
  issuer: {
    address: string;
    latitude: number;
    longitude: number;
    stats: {
      averageHolder: number;
      averageYield: number;
      listedProperties: number;
      profitDistributed: number;
      totalInvestors: number;
      totalValuation: number;
    };
    name: string;
    walletAddress: string;
    websiteUrl: string;
    isRequestingIssuer: boolean;
    errorMessage: null | string;
  };
  trades: Array<any>;
  isRequestingFeaturedProperties: boolean;
  errorMessageFeaturedProperties: null | string;
  isRequestingOfferingProperties: boolean;
  errorMessageOfferingProperties: null | string;
  isRequestingProperties: boolean;
  errorMessageProperties: null | string;
  isRequestingProperty: boolean;
  errorMessageProperty: null | string;
  isRequestingDocuments: boolean;
  errorMessageDocuments: null | string;
  isRequestingStatistics: boolean;
  errorMessageStatistics: null | string;
  isRequestingTrades: boolean;
  errorMessageTrades: null | string;
  isRequestingOnChainProperty: boolean;
  errorMessageRequestOnChainProperty: null | string;
  isRequestingOnChainOffering: boolean;
  errorMessageRequestOnChainOffering: null | string;
};
