/* eslint-disable no-use-before-define */
import i18n from 'i18n';
import { createSlice } from '@reduxjs/toolkit';
import { RESET_STATE } from './sharedActions';

// Make tour IDs into an immutable object
// And then use these IDs in order to add specific steps
// into the tour and then start it

// I'm not sure where the tour ID object should be yet.
// See other Redux slices and their constants

const initialState = {
  runTour: false,
  tourId: '',
  locale: {
    back: '',
    close: '',
    last: '',
    next: '',
    open: '',
    skip: '',
  },
};

const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    tourInitiated(state, action) {
      const { tourId } = action.payload;
      state.tourId = tourId;
      state.runTour = true;
    },
    tourClosed(state) {
      state.runTour = false;
      state.tourId = '';
    },
    initializeLocale(state) {
      state.locale = {
        back: i18n.t('tour_back'),
        close: i18n.t('tour_close'),
        last: i18n.t('tour_last'),
        next: i18n.t('tour_next'),
        open: i18n.t('tour_open'),
        skip: i18n.t('tour_skip'),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function initiateTour(tourId) {
  return (dispatch) => {
    dispatch(tourInitiated({ tourId }));
    dispatch(initializeLocale());
  };
}

export function closeTour() {
  return (dispatch) => {
    dispatch(tourClosed());
  };
}

export const { tourInitiated, tourClosed, initializeLocale } = tourSlice.actions;
export default tourSlice.reducer;
