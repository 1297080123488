/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import API from 'utils/api';
import { createNotificationByType, NOTIFICATION_TYPES } from 'utils/notification';
import i18n from 'i18n';
import { RESET_STATE } from 'store/sharedActions';
import { loginErrorReasons } from 'utils/apiErrorReasonIDs';

const initialState = {
  token: null,
  groups: [],
  site: '',
  isAuthenticating: false,
  isRequestingNewPassword: false,
  needAgreementForNewCP: false,
  expireAt: null,
  errorMessage: null,
  errorReason: null,
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    loginRequest(state) {
      state.isAuthenticating = true;
    },
    loginFailure(state, action) {
      const { reason } = action.payload;
      state.isAuthenticating = false;
      state.errorMessage = i18n.t(reason);
      state.errorReason = reason;
      state.needAgreementForNewCP = reason === loginErrorReasons.login_share_personal_info;
    },
    loginSuccess(state, action) {
      const { jwt } = action.payload;
      const { groups, exp, company } = jwtDecode(jwt);
      state.token = jwt;
      state.groups = groups;
      state.isAuthenticating = false;
      state.expireAt = exp;
      state.site = company;
    },
    loginCancel(state) {
      state.needAgreementForNewCP = false;
      state.errorMessage = null;
    },
    resetErrorState(state) {
      state.errorReason = null;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function resetError() {
  return (dispatch) => {
    dispatch(resetErrorState());
  };
}

export function userLoginRequest(loginData) {
  return (dispatch) => {
    dispatch(loginRequest());
    return API.post('/login', loginData)
      .then((response) => {
        const { data } = response;
        const { status } = data;
        if (status.status === 'SUCCESS') {
          dispatch(loginSuccess(data));
        } else {
          dispatch(loginFailure(status));
        }
      })
      .catch(() => {
        dispatch(loginFailure({ reason: loginErrorReasons.network_error }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function userLoginRequestNewCP(loginData) {
  return (dispatch) => {
    dispatch(loginRequest());
    return API.post('/register/new-company', loginData)
      .then((response) => {
        const { data } = response;
        const { status } = data;
        if (status.status === 'SUCCESS') {
          dispatch(loginSuccess(data));
        } else {
          dispatch(loginFailure(status));
        }
      })
      .catch(() => {
        dispatch(loginFailure({ reason: loginErrorReasons.network_error }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export const { loginRequest, loginFailure, loginSuccess, loginCancel, resetErrorState } =
  authenticationSlice.actions;
export default authenticationSlice.reducer;
