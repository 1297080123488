export const genericErrorReasons = {
  network_error: 'network_error',
  password_missmatch: 'password_missmatch',
  company_not_exists: 'company_not_exists',
  password_blank: 'password_blank',
  password_confirm_blank: 'password_confirm_blank',
  email_blank: 'email_blank',
};

export const loginErrorReasons = {
  ...genericErrorReasons,
  login_wrong_credentials: 'login_wrong_credentials',
  login_account_not_activated: 'login_account_not_activated',
  login_no_company: 'login_no_company',
  login_company_not_exists: 'login_company_not_exists',
  login_share_personal_info: 'login_share_personal_info',
  login_something_went_wrong: 'login_something_went_wrong',
};

export const passwordResetErrorReasons = {
  ...genericErrorReasons,
  reset_activate_first: 'reset_activate_first',
};
