import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import { initI18n } from '../i18n';

import type { RootReducer } from './rootReducer';
import rootReducer from './rootReducer';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

declare global {
  interface BigInt {
    toJSON: () => string;
    fromJSON: () => bigint;
  }
}

// eslint-disable-next-line no-extend-native
BigInt.prototype.toJSON = function () {
  return this.toString();
};

// eslint-disable-next-line no-extend-native
BigInt.prototype.fromJSON = function () {
  return BigInt(this as unknown as string);
};

/* const globalFilter = createWhitelistFilter('global', ['company']); */
const authenticationFilter = createWhitelistFilter('authentication', [
  'token',
  'groups',
  'expireAt',
  'site',
]);
const languageFilter = createWhitelistFilter('language', ['selectedLanguage']);
const currencyFilter = createWhitelistFilter('currency', ['selectedCurrency']);
const walletFilter = createWhitelistFilter('wallet', ['account', 'type']);

const persistConfig = {
  key: 'blocksquare-dashboard',
  storage,
  whitelist: ['authentication', 'language', 'currency', 'wallet'],
  transforms: [authenticationFilter, languageFilter, currencyFilter, walletFilter],
  stateReconciler: autoMergeLevel2,
};
const persistedReducer = persistReducer<RootReducer>(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      /* serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }, */
      serializableCheck: false,
    }),
  // This adds support for Redux DevTools in development mode
  devTools: process.env.NODE_ENV === 'development',
});

const persistor = persistStore(store, {}, () => {
  initI18n(store);
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { store, persistor };
