import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n';
import { resetState } from 'utils/state';
import { gqlclient, GET_COMPANY_QUERY } from 'query';
import {
  createNotificationByType,
  createNotification,
  NOTIFICATION_TYPES,
} from 'utils/notification';
import API from 'utils/api';
import { RESET_STATE } from './sharedActions';
import type { CompanyState } from 'types';
import { configFetchErrorTypes } from 'types';

const initialState: CompanyState = {
  defaultCompanyRoute: '',
  company: 'blocksquare',
  cpBytes: '',
  policy: '',
  terms: '',
  languages: [],
  offeringCurrency: '',
  configuration: {
    title: null,
    images: {},
    theme: null,
  },
  companyConfigurationFetchError: false,
  companyConfigurationFetchErrorType: '',
  defaultCompanyRouteFetchError: false,
  defaultCompanyRouteNotFound: false,
  isFetchingDefaultCompany: false,
  isUpdatingCompany: false,
  updateCompanyErrorMessage: null,
  isRequestingOnChainData: false,
  onChainRequestErrorMessage: null,
  isRequestingLanguages: false,
  languagesRequestError: null,
  isRequestingTermsAndPolicy: false,
  errorRequestingTermsAndPolicy: null,
  isRequestingCurrency: false,
  errorRequestingCurrency: null,
  isRequestingCompanyDetails: false,
  errorRequestingCompanyDetails: null,
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    companyChangeRequest(state) {
      state.isUpdatingCompany = true;
    },
    companyChangeSuccess(state, action) {
      const { company } = action.payload;
      state.company = company;
      state.isUpdatingCompany = false;
      state.updateCompanyErrorMessage = null;
    },
    companyChangeFailure(state, action) {
      const { reason } = action.payload;
      state.isUpdatingCompany = false;
      state.updateCompanyErrorMessage = reason;
    },
    companyOnChainRequest(state) {
      state.isRequestingOnChainData = true;
    },
    companyOnChainSuccess(state, action) {
      const { cpBytes } = action.payload;
      state.cpBytes = cpBytes;
      state.isRequestingOnChainData = false;
      state.onChainRequestErrorMessage = null;
    },
    companyOnChainFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingOnChainData = false;
      state.onChainRequestErrorMessage = reason;
    },
    companyLanguageRequest(state) {
      state.isRequestingLanguages = true;
    },
    companyLanguageSuccess(state, action) {
      const { languages } = action.payload;
      state.languages = languages;
      state.isRequestingLanguages = false;
    },
    companyLanguageFailure(state, action) {
      const { reason } = action.payload;
      state.languagesRequestError = reason;
      state.isRequestingLanguages = false;
    },
    companyConfigurationFetchSuccess(state, action) {
      const {
        data: { brandColor, brandImage, faviconImage, title },
        companyId,
      } = action.payload;

      // We must reset the error status in case this success was
      // triggered by a retry.
      state.companyConfigurationFetchError = false;
      state.configuration = {
        title,
        images: { brandImage, faviconImage },
        theme: { identifier: companyId, brandColor },
      };
    },
    companyConfigurationFetchFailure(state, action) {
      const { errorType } = action.payload;

      state.companyConfigurationFetchErrorType = errorType;
      state.companyConfigurationFetchError = true;
    },
    companyConfigurationFetchRetry(state) {
      state.companyConfigurationFetchErrorType = '';
    },
    defaultCompanyRouteFetchSuccess(state, action) {
      const { defaultRoute } = action.payload;

      state.defaultCompanyRoute = defaultRoute;
      state.isFetchingDefaultCompany = false;
    },
    defaultCompanyRouteFetchFailure(state) {
      state.isFetchingDefaultCompany = false;
      state.defaultCompanyRouteFetchError = true;
    },
    defaultCompanyRouteNotFound(state) {
      state.isFetchingDefaultCompany = false;
      state.defaultCompanyRouteNotFound = true;
    },
    defaultCompanyRouteFetchRequest(state) {
      state.isFetchingDefaultCompany = true;
    },
    defaultCompanyRouteFetchRetry(state) {
      state.defaultCompanyRouteFetchError = false;
    },
    companyTermsAndPolicyChangeRequest(state) {
      state.isRequestingTermsAndPolicy = true;
    },
    companyTermsAndPolicyChangeSuccess(state, action) {
      const { terms, policy } = action.payload;
      state.terms = terms;
      state.policy = policy;
      state.isRequestingTermsAndPolicy = false;
    },
    companyTermsAndPolicyChangeFailure(state, action) {
      const { reason } = action.payload;
      state.errorRequestingTermsAndPolicy = reason;
      state.isRequestingTermsAndPolicy = false;
    },
    companyCurrencyRequest(state) {
      state.isRequestingCurrency = true;
    },
    companyCurrencySuccess(state, action) {
      const { offeringCurrency } = action.payload;
      state.offeringCurrency = offeringCurrency;
      state.isRequestingCurrency = false;
    },
    companyCurrencyFailure(state, action) {
      const { reason } = action.payload;
      state.errorRequestingCurrency = reason;
      state.isRequestingCurrency = false;
    },
    companyDetailsFailure(state, action) {
      const { reason } = action.payload;
      state.errorRequestingCompanyDetails = reason;
      state.isRequestingCompanyDetails = false;
    },
    companyDetailsRequest(state, action) {
      const { isRequesting } = action.payload;
      state.isRequestingCompanyDetails = isRequesting;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function fetchDefaultCompanyRoute() {
  return async (dispatch) => {
    dispatch(defaultCompanyRouteFetchRequest());
    return API.get('company/handle')
      .then((response) => {
        const {
          data: { handle },
        } = response;

        if (!handle || typeof handle !== 'string' || handle.length <= 1) {
          // This should render a 404 inside of of the Routes component
          dispatch(defaultCompanyRouteNotFound());
        } else {
          dispatch(defaultCompanyRouteFetchSuccess({ defaultRoute: handle }));
        }
      })
      .catch(() => {
        const error = i18n.t('default_company_route_fetching_error');
        dispatch(createNotification(error, 'error'));
        // The following should render an error screen in the Routes component
        dispatch(defaultCompanyRouteFetchFailure());
      });
  };
}

export function setCompany(company) {
  return async (dispatch, getState) => {
    const {
      authentication: { site, token, expireAt },
      company: {
        company: currentCompany,
        languages,
        terms,
        policy,
        offeringCurrency,
        errorRequestingCurrency,
        configuration: { theme },
      },
    } = getState();

    // Destroy active session and reset state
    if (token && (!site || company !== site)) {
      return dispatch(resetState());
    }

    if (company !== currentCompany || !policy || !terms) {
      dispatch(queryCompanyTermsAndPolicy(company));
    }

    // TODO
    // I will probably need to add a few more conditions because
    // the endpoint contacted by this function is going to provide
    // so much more than just the theme and brand images
    if (company !== currentCompany || !theme) {
      dispatch(queryCompanyConfiguration(company));
    }

    const tokenExpirationDate = expireAt ? expireAt * 1000 : 0;
    const currentTimestamp = Date.now();
    if (token && currentTimestamp < tokenExpirationDate) {
      if (
        company !== currentCompany ||
        languages.length === 0 ||
        (!offeringCurrency && errorRequestingCurrency === null)
      ) {
        dispatch(queryCompanyDetails());
      }

      dispatch(companyOnChainRequest());
      gqlclient
        .query({
          query: GET_COMPANY_QUERY,
          variables: {
            companyName: company,
          },
        })
        .then((result) => {
          const {
            data: { certifiedPartners },
          } = result;
          if (certifiedPartners && certifiedPartners.length > 0) {
            const certifiedPartner = certifiedPartners[0];
            dispatch(companyOnChainSuccess({ cpBytes: certifiedPartner.id }));
          }
        })
        .catch((error) => {
          dispatch(companyOnChainFailure({ reason: error.message }));
        });
    }

    dispatch(companyChangeRequest());
    return dispatch(companyChangeSuccess({ company }));
  };
}

// TODO
// This function should expand so that it can provide the app with all of the
// necessary company configuration so that we don't have to rely on multiple endpoints.
// e.g. we will get terms and policy from this endpoint as well
export function queryCompanyConfiguration(company) {
  return async (dispatch) => {
    const { invalidData, serverError } = configFetchErrorTypes;
    return API.get(`/company/${company}/configuration`)
      .then((response) => {
        const {
          data: { brandColor, brandImage, faviconImage, title },
        } = response;

        if (!brandColor || !brandImage || !faviconImage || !title) {
          const error = i18n.t('theme_configuration_fetching_insufficient_data');
          dispatch(createNotification(error, 'error'));
          dispatch(companyConfigurationFetchFailure({ errorType: invalidData }));
        } else {
          dispatch(companyConfigurationFetchSuccess({ data: response.data, companyId: company }));
        }
      })
      .catch(() => {
        const error = i18n.t('theme_configuration_fetching_error');
        dispatch(createNotification(error, 'error'));
        dispatch(companyConfigurationFetchFailure({ errorType: serverError }));
      });
  };
}

export function queryCompanyTermsAndPolicy(company) {
  return async (dispatch) => {
    dispatch(companyTermsAndPolicyChangeRequest());
    return API.get(`/company/${company}/terms-policy`, {})
      .then((response) => {
        const {
          data: { terms, policy },
        } = response;

        dispatch(companyTermsAndPolicyChangeSuccess({ terms, policy }));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(companyTermsAndPolicyChangeFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function queryCompanyDetails() {
  return async (dispatch, getState) => {
    dispatch(companyDetailsRequest({ isRequesting: true }));
    return API.get(`/company/details`, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const {
          data: { languages, defaultCurrency },
        } = response;

        // Language setting section
        if (languages.length > 0) {
          const tempLanguages = languages.map((languageData) => {
            const { language, code: countryCode } = languageData;
            return {
              language: language.toLowerCase(),
              countryCode,
            };
          });
          dispatch(companyLanguageSuccess({ languages: tempLanguages }));
        } else {
          const errorMessage = i18n.t('marketplace_information_fetch_error');
          dispatch(companyLanguageFailure({ reason: errorMessage }));
          dispatch(createNotification(errorMessage, 'error'));
        }
        // //////

        // Offering currency setting section
        if (defaultCurrency) {
          dispatch(companyCurrencySuccess({ offeringCurrency: defaultCurrency }));
        } else {
          const errorMessage = i18n.t('marketplace_information_fetch_error');
          dispatch(companyCurrencyFailure({ reason: errorMessage }));
          dispatch(createNotification(errorMessage, 'error'));
        }
        // //////

        // StableCoin setting section
        // StableCoin section is WIP
        // We plan to use the preferred CP's stablecoin for displaying
        // prices and support payments with it.
        // //////
        dispatch(companyDetailsRequest({ isRequesting: false }));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(companyDetailsFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export const {
  companyChangeRequest,
  companyChangeSuccess,
  companyChangeFailure,
  companyOnChainRequest,
  companyOnChainSuccess,
  companyOnChainFailure,
  companyLanguageRequest,
  companyLanguageSuccess,
  companyLanguageFailure,
  companyTermsAndPolicyChangeRequest,
  companyTermsAndPolicyChangeSuccess,
  companyTermsAndPolicyChangeFailure,
  companyConfigurationFetchSuccess,
  companyConfigurationFetchFailure,
  companyConfigurationFetchRetry,
  defaultCompanyRouteFetchRequest,
  defaultCompanyRouteFetchSuccess,
  defaultCompanyRouteFetchFailure,
  defaultCompanyRouteFetchRetry,
  defaultCompanyRouteNotFound,
  companyCurrencyRequest,
  companyCurrencySuccess,
  companyCurrencyFailure,
  companyDetailsFailure,
  companyDetailsRequest,
} = companySlice.actions;
export default companySlice.reducer;
