import { lazy } from 'react';

// Home pages
const Welcome = lazy(() => import('pages/home/Welcome'));
const Kyc = lazy(() => import('pages/home/Kyc'));
const KycCallback = lazy(() => import('pages/home/KycCallback'));

// Portfolio pages
const PortfolioOverview = lazy(() => import('pages/portfolio/Overview'));

// Marketplace pages
const MarketplaceOverview = lazy(() => import('pages/marketplace/Overview'));
const Property = lazy(() => import('pages/marketplace/Property'));

// Offering pages
const OfferingOverview = lazy(() => import('pages/offering/Overview'));

// Auth pages
const SignIn = lazy(() => import('pages/auth/SignIn'));
const SignUp = lazy(() => import('pages/auth/SignUp'));
const ResetPassword = lazy(() => import('pages/auth/ResetPassword'));
const Verify = lazy(() => import('pages/auth/Verify'));
const ForgotPassword = lazy(() => import('pages/auth/ForgotPassword'));

// Account pages
const Settings = lazy(() => import('pages/account/Settings'));
const Wallets = lazy(() => import('pages/account/Wallets'));
// const Funding = lazy(() => import('pages/account/Funding'));
const History = lazy(() => import('pages/account/History'));
const OfferingsHistory = lazy(() => import('pages/account/Offerings'));

// Pro forma page
const OfferingProForma = lazy(() => import('components/offering/OfferingProForma'));

const homeRoutes = {
  id: 'home',
  path: '/',
  groups: 'user',
  containsHome: true,
  component: Welcome,
};

const portfolioRoutes = {
  id: 'portfolio',
  path: '/portfolio',
  groups: 'user',
  component: PortfolioOverview,
  /* disabledWithoutWallet: true, */
};

const marketplaceRoutes = {
  id: 'marketplace',
  path: '/marketplace',
  groups: 'user',
  hideInSidebar: false,
  component: MarketplaceOverview,
  children: [
    {
      id: 'MarketplacePropertyInfo',
      // Regex to check for ethereum address format
      path: '/marketplace/property/:id(0x[a-fA-F0-9]{40})',
      name: 'PropertyInfo',
      component: Property,
      hideInSidebar: true,
    },
  ],
};

const offeringRoutes = {
  id: 'offerings',
  path: '/offerings',
  groups: 'user',
  component: OfferingOverview,
};

const accountRoutes = {
  id: 'account',
  path: '/account',
  component: Settings,
  groups: 'user',
  children: [
    {
      id: 'account_wallets',
      path: '/account/wallets',
      component: Wallets,
    },
    {
      id: 'account_trades',
      path: '/account/history',
      component: History,
    },
    /*
    We don't have support for direct fiat to DAI funding since we are yet to
    find an appropriate provider

    Enable once we find and enable an appropriate provider
    {
      id: 'account_funding',
      path: '/account/funding',
      component: Funding,
    },
    */
    {
      id: 'account_offerings',
      path: '/account/offerings',
      component: OfferingsHistory,
    },
  ],
};

const helpRoutes = {
  id: 'help',
  path: '/help',
  groups: 'user',
  hideInSidebar: true,
};

const kycRoutes = {
  id: 'kyc',
  path: '/kyc',
  hideInSidebar: true,
  component: Kyc,
};

const kycRoutes2 = {
  id: 'KYCCallback',
  path: '/kyc/callback',
  name: 'KycCallback',
  component: KycCallback,
};

const authRoutes = {
  id: 'Auth',
  path: '/auth',
  hideInSidebar: true,
  children: [
    {
      id: 'AuthSignIn',
      path: '/auth/sign-in',
      name: 'Sign In',
      component: SignIn,
    },
    {
      id: 'AuthSignUp',
      path: '/auth/sign-up',
      name: 'Sign Up',
      component: SignUp,
    },
    {
      id: 'AuthResetPassword',
      path: '/reset-password',
      name: 'Reset Password',
      component: ResetPassword,
    },
    {
      id: 'AuthVerify',
      path: '/verify',
      name: 'Verify Email',
      component: Verify,
    },
    {
      id: 'AuthForgotPassword',
      path: '/forgot-password/:token',
      name: 'Forgot Password',
      component: ForgotPassword,
    },
  ],
};

export const offeringProFormaRoutes = [
  {
    id: 'ProForma',
    path: '/offeringProForma',
    name: 'Offering Pro Forma',
    component: OfferingProForma,
  },
];

export const dashboard = [
  homeRoutes,
  portfolioRoutes,
  marketplaceRoutes,
  accountRoutes,
  offeringRoutes,
  helpRoutes,
];

export const kyc = [kycRoutes];
export const callback = [kycRoutes2];
export const auth = [authRoutes];

export default [
  homeRoutes,
  portfolioRoutes,
  marketplaceRoutes,
  offeringRoutes,
  accountRoutes,
  helpRoutes,
  authRoutes,
];
