import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';

import CustomHead from 'components/custom/CustomHead';
import Notifier from 'components/Notifier';

import generateTheme, { fallbackTheme } from './theme';
import Routes from './routes/Routes';
import { useAppSelector } from 'store';

function App() {
  const companyConfiguration = useAppSelector((store) => store.company.configuration);
  const { theme: companyTheme } = companyConfiguration;

  const isThemeReady = companyTheme || false;

  const currentTheme = companyTheme
    ? generateTheme(companyTheme.identifier, companyTheme.brandColor)
    : fallbackTheme;

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={currentTheme}>
        <ThemeProvider theme={currentTheme}>
          {isThemeReady ? <CustomHead /> : null}
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            maxSnack={3}
          >
            <Notifier />
          </SnackbarProvider>
          <Routes isThemeReady={isThemeReady} />
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
