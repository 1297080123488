/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n';
import API from 'utils/api';
import {
  createNotificationByType,
  createNotification,
  NOTIFICATION_TYPES,
} from 'utils/notification';
import { genericErrorReasons } from 'utils/apiErrorReasonIDs';
import { RESET_STATE } from './sharedActions';

const initialState = {
  isRegistering: false,
  isRequestingResendActivation: false,
  alreadyRegistered: false,
  email: null,
  errorMessage: null,
  errorMessageResendActivation: null,
  token: {
    isVerifying: false,
    errorMessage: null,
    verified: false,
  },
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    registrationRequest(state) {
      state.isRegistering = true;
      state.email = null;
    },
    registrationFailure(state, action) {
      const { reason } = action.payload;
      state.isRegistering = false;
      state.errorMessage = reason;
      state.email = null;
    },
    registrationSuccess(state, action) {
      const { email } = action.payload;
      state.isRegistering = false;
      state.alreadyRegistered = true;
      state.email = email;
    },
    tokenRequest(state) {
      state.token.isVerifying = true;
    },
    tokenFailure(state, action) {
      const { reason } = action.payload;
      state.token.isVerifying = false;
      state.token.errorMessage = reason;
    },
    tokenSuccess(state) {
      state.token.isVerifying = false;
      state.token.verified = true;
      state.token.errorMessage = null;
    },
    resendActivationRequest(state) {
      state.isRequestingResendActivation = true;
    },
    resendActivationSuccess(state, action) {
      const { email } = action.payload;
      state.email = email;
      state.isRequestingResendActivation = false;
    },
    resendActivationFailure(state, action) {
      const { reason } = action.payload;
      state.errorMessageResendActivation = i18n.t(reason);
      state.isRequestingResendActivation = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function userRegistrationRequest(registrationData) {
  return (dispatch) => {
    dispatch(registrationRequest());
    return API.post('/register', registrationData)
      .then((response) => {
        const { data } = response;
        if (data.status === 'SUCCESS') {
          // Setting data for first user login welcome tour dialog
          // - see React Joyride configuraiton in Dashboard.js
          const userId = data.reason;
          window.localStorage.setItem(`firstLoginTourData-${userId}`, 'true');
          //

          dispatch(registrationSuccess(registrationData));
        } else {
          dispatch(registrationFailure(data));
        }
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(registrationFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function verifyTokenRequest(token) {
  return (dispatch) => {
    dispatch(tokenRequest());
    return API.post(`/activate/${token}`, { token })
      .then((response) => {
        const { data } = response;
        if (data.status === 'SUCCESS') {
          dispatch(tokenSuccess());
        } else {
          dispatch(tokenFailure(data));
        }
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(tokenFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function userResendActivationRequest(email) {
  return (dispatch) => {
    dispatch(resendActivationRequest());
    return API.post('/resend-activation', { email })
      .then((response) => {
        const { data } = response;
        if (data.status === 'SUCCESS') {
          dispatch(resendActivationSuccess({ email }));
          const successMsg = i18n.t('verify_email_activation_link_re_sent');
          dispatch(createNotification(successMsg));
          return 'success';
        }

        dispatch(resendActivationFailure(data));
        const failureMsg = i18n.t(data.reason);
        dispatch(createNotification(failureMsg, 'error'));
        return 'failure';
      })
      .catch(() => {
        dispatch(resendActivationFailure({ reason: genericErrorReasons.network_error }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export const {
  registrationFailure,
  registrationRequest,
  registrationSuccess,
  resendActivationFailure,
  resendActivationRequest,
  resendActivationSuccess,
  tokenFailure,
  tokenRequest,
  tokenSuccess,
} = registrationSlice.actions;
export default registrationSlice.reducer;
