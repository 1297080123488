import InvestmentABI from 'contracts/Investment.json';
import { Contract } from 'ethers';
import { getProvider } from './helper';
import { getTokenAllowance, setTokenAllowance } from './erc20';
import { handleAllowance } from './allowance';
import { UNLIMITED_ALLOWANCE_IN_WEI_BIG_INT_TO_CHECK_AGAINST } from 'utils/constants';

// Marko M - I commented the unlimited allowance number above and set the new one because
// of the issue where the client was asked to set their allowance each time
// they wanted to buy Offering tokens with their DAI.

// What was happening is that the Smart Contract was subtracting from allowance the amount of tokens
// the user bought in the previous buying session and it made the allowance less than our
// commented Unlimited number and promted the user to set their allowance back to our Unlimited number
// but in actuality 10^24 is sufficient enough.

export async function getCurrentSupply(propertyTokenAddress: string): Promise<bigint> {
  const provider = getProvider();

  const investmentContract = new Contract(
    process.env.REACT_APP_INVESTMENT_ADDRESS as string,
    InvestmentABI,
    provider
  );

  const result = await investmentContract.currentSupply(propertyTokenAddress);
  return result;
}

export async function getHardCap(propertyTokenAddress: string): Promise<bigint> {
  const provider = getProvider();

  const investmentContract = new Contract(
    process.env.REACT_APP_INVESTMENT_ADDRESS as string,
    InvestmentABI,
    provider
  );

  const result = await investmentContract.hardCap(propertyTokenAddress);
  /* console.log(`HardCap: ${result}`); */
  return result;
}

// Only checks if the investment allowance is approved
export async function checkInvestmentAllowance(spenderAddress: string): Promise<boolean> {
  const tokenAllowance = await getTokenAllowance(
    process.env.REACT_APP_DAI_ADDRESS as string,
    spenderAddress,
    process.env.REACT_APP_INVESTMENT_ADDRESS as string
  );

  const tokenHasEnoughAllowance =
    tokenAllowance >= UNLIMITED_ALLOWANCE_IN_WEI_BIG_INT_TO_CHECK_AGAINST;

  return tokenHasEnoughAllowance;
}

export async function approveInvestmentAllowance(): Promise<boolean> {
  return await setTokenAllowance(
    process.env.REACT_APP_DAI_ADDRESS as string,
    process.env.REACT_APP_INVESTMENT_ADDRESS as string
  );
}

export async function investOrder(
  bsptTokenAddress: string,
  daiAmount: bigint,
  spenderAddress: string
) {
  const allowance = await handleAllowance(
    process.env.REACT_APP_DAI_ADDRESS as string,
    spenderAddress,
    process.env.REACT_APP_INVESTMENT_ADDRESS as string,
    UNLIMITED_ALLOWANCE_IN_WEI_BIG_INT_TO_CHECK_AGAINST
  );
  if (!allowance) return null;

  const provider = getProvider();
  const signer = await provider.getSigner();

  const investmentContract = new Contract(
    process.env.REACT_APP_INVESTMENT_ADDRESS as string,
    InvestmentABI,
    signer
  );

  return [investmentContract.invest(bsptTokenAddress, daiAmount), spenderAddress];
}
