/* eslint-disable import/prefer-default-export */
import { store } from 'store';

export function getCompanyInformation() {
  const {
    company: {
      configuration: {
        title,
        theme: { identifier, brandColor },
        images: { brandImage, faviconImage },
      },
    },
  } = store.getState();

  const companyInformation = {
    brandColor,
    brandImage,
    faviconImage,
    identifier,
    title,
  };

  return companyInformation;
}
