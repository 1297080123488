export const DIALOG_TYPES = {
  ADD_BANK_ACCOUNT: 1,
  ASSET_EXCHANGE: 2,
  BUY_OFFERING_TOKENS: 3,
  CHANGE_WALLET_NAME: 4,
  CLAIM_RENT: 5,
  CONNECT_WALLET: 6,
  DISCONNECT_WALLET: 7,
  EXCHANGE_BUY: 8,
  EXCHANGE_SELL: 9,
  PASSWORD_CHANGE: 10,
  UNLOCK_PROPERTY: 11,
  UNLOCK_TOKEN: 12,
  REQUEST_WITHDRAW: 13,
  CONFIRM_BUY: 14,
  CONNECT_WALLET_NEW: 15,
  WRONG_NETWORK: 16,
  TRANSACTION_FAILED: 17,
  TRANSACTION_PENDING: 18,
  TRANSACTION_SUCCESS: 19,
  EXCHANGE_NO_SELLERES: 20,
  ADD_FUNDS: 21,
  WELCOME_TOUR: 22,
  WALLET_REGISTRATION_INITIATED: 23,
} as const;

export type DialogKeys = (typeof DIALOG_TYPES)[keyof typeof DIALOG_TYPES];

// We should possibly rework the dialog handler to have a type safe data usage.
export type DialogsState = {
  activeDialog: DialogKeys | null;
  activeDialogData: any;
};
