export const UNLIMITED_ALLOWANCE_IN_WEI_BIG_INT_TO_SET =
  115792089237316195423570985008687907853269984665640564039457584007913129639935n;
// So, as long as the allowance is higher than 10^24 we will not prompt the user to set their allowance.
export const UNLIMITED_ALLOWANCE_IN_WEI_BIG_INT_TO_CHECK_AGAINST = BigInt(10 ** 24);

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';
export const DECIMALS_18_BI = BigInt(10 ** 18);

export const SALE_MODE = {
  PRE_SALE_START: 0,
  PRE_SALE_END: 1,
  MAIN_SALE_START: 2,
  MAIN_SALE_END: 3,
};
