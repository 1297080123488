import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n';
import API from 'utils/api';
import { hideDialogs, showDialog } from 'store/dialogs';
import {
  createNotification,
  createNotificationByType,
  NOTIFICATION_TYPES,
} from 'utils/notification';
import { batchFillOrders, isOrderValid } from 'utils/web3';
import { RESET_STATE } from './sharedActions';
import type {
  HistoricTradeRaw,
  HistoricTrades,
  OrderWrapper,
  OrderWrapperRaw,
  TradeState,
} from 'types';
import { LimitOrderBI, DIALOG_TYPES } from 'types';

const initialState: TradeState = {
  errorMessageHistory: null,
  errorMessageOrders: null,
  errorMessagePostFillOrder: null,
  errorMessagePostSellOrder: null,
  history: [],
  isPostingFillOrder: false,
  isPostingSellOrder: false,
  isRequestingHistory: false,
  isRequestingOrders: false,
  orders: [],
  userHistory: [],
  isRequestingUserHistory: false,
  errorMessageUserHistory: null,
};

const tradeSlice = createSlice({
  name: 'trade',
  initialState,
  reducers: {
    historyFetchRequest(state) {
      state.isRequestingHistory = true;
    },
    historyFetchSuccess(state, { payload }: PayloadAction<HistoricTrades>) {
      state.isRequestingHistory = true;
      state.history = payload;
      state.errorMessageHistory = null;
    },
    historyFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingHistory = true;
      state.errorMessageHistory = reason;
    },
    postFillOrderRequest(state) {
      state.isPostingFillOrder = true;
    },
    postFillOrderSuccess(state) {
      state.isPostingFillOrder = false;
    },
    postFillOrderFailure(state, action) {
      const { reason } = action.payload;
      state.errorMessagePostFillOrder = reason;
      state.isPostingFillOrder = false;
    },
    postSellOrderRequest(state) {
      state.isPostingSellOrder = true;
    },
    postSellOrderSuccess(state) {
      state.isPostingSellOrder = false;
    },
    postSellOrderFailure(state, action) {
      const { reason } = action.payload;
      state.errorMessagePostSellOrder = reason;
      state.isPostingSellOrder = false;
    },
    ordersFetchRequest(state) {
      state.isRequestingOrders = true;
    },
    ordersFetchSuccess(state, { payload }: PayloadAction<Array<OrderWrapper>>) {
      state.isRequestingOrders = false;
      state.orders = payload;
      state.errorMessageOrders = null;
    },
    ordersFetchFailure(state, action) {
      const { reason } = action.payload;
      state.errorMessageOrders = reason;
      state.isRequestingOrders = false;
    },
    userHistoryFetchRequest(state) {
      state.isRequestingUserHistory = true;
    },
    userHistoryFetchSuccess(state, action) {
      state.isRequestingUserHistory = true;
      state.userHistory = action.payload;
      state.errorMessageUserHistory = null;
    },
    userHistoryFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingUserHistory = true;
      state.errorMessageUserHistory = reason;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function tokenHistoryFetchRequest(tokenAddress: string) {
  return async (dispatch, getState) => {
    dispatch(historyFetchRequest());
    return API.get<Array<HistoricTradeRaw>>(`/trade/history/${tokenAddress}`, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;

        const trades: HistoricTrades = data.map((tradeRaw) => {
          const { volume, cost, ...trade } = tradeRaw;
          return Object.assign(trade, {
            cost: BigInt(cost),
            volume: BigInt(volume),
          });
        });

        dispatch(historyFetchSuccess(trades));
      })
      .catch((error) => {
        console.error(error);
        const networkError = i18n.t('network_error');
        dispatch(historyFetchFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function userTradeHistoryFetchRequest() {
  return async (dispatch, getState) => {
    dispatch(userHistoryFetchRequest());
    return API.get('/trade/history/user', {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        dispatch(userHistoryFetchSuccess(data));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(userHistoryFetchFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

// Filter with async function
async function filter(arr, callback) {
  // eslint-disable-next-line symbol-description
  const fail = Symbol();
  return (
    await Promise.all(arr.map(async (item) => ((await callback(item)) ? item : fail)))
  ).filter((i) => i !== fail);
}

export function tokenOrdersFetchRequest(tokenAddress: string, chainId: number) {
  return async (dispatch, getState) => {
    dispatch(ordersFetchRequest());
    return API.get<Array<OrderWrapperRaw>>(`/trade/${tokenAddress}`, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then(async (response) => {
        const { data: ordersRaw } = response;
        const orders = ordersRaw.map((order) => {
          const { signature, ratio, limitOrder } = order;
          const { takerTokenFeeAmount, expiry, salt, makerAmount, takerAmount, ...limitOrderTemp } =
            limitOrder;
          const limitOrderData = Object.assign(limitOrderTemp, {
            takerTokenFeeAmount: BigInt(takerTokenFeeAmount),
            expiry: BigInt(expiry),
            salt: BigInt(salt),
            makerAmount: BigInt(makerAmount),
            takerAmount: BigInt(takerAmount),
          });

          return {
            limitOrder: new LimitOrderBI({ ...limitOrderData }),
            signature,
            ratio,
          };
        });
        const validOrders: Array<OrderWrapper> = await filter(orders, async (order) => {
          const isValid = await isOrderValid(order, chainId);
          return isValid;
        });
        dispatch(ordersFetchSuccess(validOrders));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(ordersFetchFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function postSellOrder(orderData, chainId: number) {
  return async (dispatch, getState) => {
    dispatch(postSellOrderRequest());
    if (orderData === null) {
      const orderError = i18n.t('notification_create_order_error');
      dispatch(postSellOrderFailure({ reason: orderError }));
      dispatch(createNotificationByType(NOTIFICATION_TYPES.ORDER_CREATION_ERROR));
      return null;
    }
    return API.post('/trade', orderData, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        if (data.status === 'SUCCESS') {
          dispatch(postSellOrderSuccess());
          dispatch(hideDialogs());
          dispatch(tokenHistoryFetchRequest(orderData.makerToken));
          dispatch(tokenOrdersFetchRequest(orderData.makerToken, chainId));
          dispatch(createNotificationByType(NOTIFICATION_TYPES.ORDER_PLACED));
        } else {
          dispatch(postSellOrderFailure(data));
          dispatch(hideDialogs());
          dispatch(createNotification(data.reason, 'error'));
        }
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(postSellOrderFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        dispatch(hideDialogs());
      });
  };
}

export function fillOrders(
  bsptTokenAddress,
  orders,
  originalOrdersMap,
  formValues,
  chainId: number,
  callback: () => void
) {
  return async (dispatch) => {
    dispatch(postFillOrderRequest());
    batchFillOrders(bsptTokenAddress, orders, originalOrdersMap, formValues, chainId)
      .then((tradeData) => {
        dispatch(postFillOrderSuccess());
        dispatch(tokenOrdersFetchRequest(bsptTokenAddress, chainId));
        dispatch(hideDialogs());
        dispatch(
          showDialog({
            type: DIALOG_TYPES.CONFIRM_BUY,
            data: {
              amount: parseFloat(formValues.amount),
              token: formValues.symbol,
              transactionHash: tradeData[0].txHash,
            },
          })
        );
        return dispatch(createNotificationByType(NOTIFICATION_TYPES.ORDER_FILLED));
      })
      .catch(() => {
        const orderError = i18n.t('notification_create_order_error');
        dispatch(postFillOrderFailure({ reason: orderError }));
        dispatch(hideDialogs());
        return dispatch(createNotificationByType(NOTIFICATION_TYPES.ORDER_CREATION_ERROR));
      })
      .finally(() => {
        callback();
      });
  };
}

export const {
  historyFetchFailure,
  historyFetchRequest,
  historyFetchSuccess,
  ordersFetchFailure,
  ordersFetchRequest,
  ordersFetchSuccess,
  postFillOrderFailure,
  postFillOrderRequest,
  postFillOrderSuccess,
  postSellOrderFailure,
  postSellOrderRequest,
  postSellOrderSuccess,
  userHistoryFetchFailure,
  userHistoryFetchSuccess,
  userHistoryFetchRequest,
} = tradeSlice.actions;
export default tradeSlice.reducer;
