import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchDefaultCompanyRoute as fetchDefaultCompanyRouteFunc } from 'store/company';
import {
  auth as authRoutes,
  callback as callbackRoutes,
  dashboard as dashboardRoutes,
  kyc as kycRoutes,
  offeringProFormaRoutes,
} from './index';
import Loader from '../components/custom/Loader';
import { configFetchErrorTypes } from 'types';

const AuthLayout = lazy(() => import('../layouts/Auth'));
const DashboardLayout = lazy(() => import('../layouts/Dashboard'));
const KycLayout = lazy(() => import('../layouts/Kyc'));
const DefaultLayout = lazy(() => import('../layouts/DefaultLayout'));
const RouteWrapper = lazy(() => import('../components/RouteWrapper'));
const Page404 = lazy(() => import('../pages/auth/Page404'));
const AppSetupErrorPage = lazy(() => import('../pages/auth/AppSetupError'));
const NoMobile = lazy(() => import('../pages/auth/NoMobile'));

const renderSingleRoute = (Layout, route, isThemeReady) => {
  return (
    <RouteWrapper
      key={route.id}
      path={`/:company${route.path}`}
      exact
      layout={Layout}
      component={route.component}
      restrictedTo={route.restrictedTo}
      isThemeReady={isThemeReady}
    />
  );
};

const renderRoutes = (Layout, routes, isThemeReady) =>
  routes.map((route) => {
    let renderedRoutes = [];
    if (route.children) {
      renderedRoutes = route.children.map((childrenRoute) => {
        return renderSingleRoute(Layout, childrenRoute, isThemeReady);
      });
    }
    renderedRoutes.push(renderSingleRoute(Layout, route, isThemeReady));
    return renderedRoutes;
  });

function Routes(props) {
  const { isThemeReady, company, fetchDefaultCompanyRoute } = props;

  const {
    defaultCompanyRoute,
    defaultCompanyRouteFetchError,
    defaultCompanyRouteNotFound,
    isFetchingDefaultCompany,
    companyConfigurationFetchErrorType,
  } = company;

  // In case the defaultCompany isn't set inside of the company Redux state slice, we go and
  // fetch what the default company is
  useEffect(() => {
    if (!defaultCompanyRoute && !defaultCompanyRouteFetchError) {
      fetchDefaultCompanyRoute();
    }
  }, [defaultCompanyRoute, defaultCompanyRouteFetchError, fetchDefaultCompanyRoute]);

  if (isFetchingDefaultCompany) {
    return <Loader size="6rem" centerLoader />;
  }

  // When we try to navigate to the root route, the Redirect that's before the last one
  // redirects us back to the default company and we go back to the switch

  return (
    <Router>
      <Suspense fallback={<Loader size="6rem" centerLoader />}>
        <Switch>
          <Route exact path="/error" component={AppSetupErrorPage} />
          {(companyConfigurationFetchErrorType !== '' &&
            companyConfigurationFetchErrorType !== configFetchErrorTypes.serverError) ||
          defaultCompanyRouteFetchError ? (
            <Redirect to="/error" />
          ) : null}

          <Route
            exact
            path="/404"
            render={() => (
              <Page404 companyConfigFetchErrType={companyConfigurationFetchErrorType} />
            )}
          />
          {defaultCompanyRouteNotFound ||
          companyConfigurationFetchErrorType === configFetchErrorTypes.serverError ? (
            <Redirect to="/404" />
          ) : null}

          <Redirect exact from="/" to={`/${defaultCompanyRoute}`} />

          {renderRoutes(DashboardLayout, dashboardRoutes, isThemeReady)}
          {renderRoutes(DashboardLayout, kycRoutes, isThemeReady)}
          {renderRoutes(KycLayout, callbackRoutes, isThemeReady)}
          {renderRoutes(AuthLayout, authRoutes, isThemeReady)}
          {renderRoutes(DefaultLayout, offeringProFormaRoutes, isThemeReady)}
          <Route exact path="/:company/404" component={Page404} />
          <Route exact path="/:company/noMobile" component={NoMobile} />
          <Route
            exact
            path="/:company/*"
            render={(routeProps) => <Redirect to={`/${routeProps.match.params.company}/404`} />}
          />
          <Redirect from="*" to="/404" />
        </Switch>
      </Suspense>
    </Router>
  );
}

const mapStateToProps = (store) => ({
  company: store.company,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDefaultCompanyRoute: () => dispatch(fetchDefaultCompanyRouteFunc()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
