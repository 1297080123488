import type { ComponentType } from 'react';
import { useWeb3React } from '@web3-react/core';

export function withWeb3<P extends object>(Component: ComponentType<P>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function ComponentWithWeb3(props: any) {
    const web3React = useWeb3React();
    return <Component {...props} web3Context={web3React} />;
  }
  return ComponentWithWeb3;
}
