/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n';
import API from 'utils/api';
import { createNotificationByType, NOTIFICATION_TYPES } from 'utils/notification';
import { languageChangeRequest, languageChangeSuccess } from 'store/language';
import { RESET_STATE } from './sharedActions';

const initialState = {
  isInitialState: true,
  isFetchingUserInformations: false,
  hasFetchedInitialUserInformation: false,
  email: '',
  birth: '',
  country: '',
  firstName: '',
  lastName: '',
  userId: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserInformationsRequest(state) {
      state.isFetchingUserInformations = true;
    },
    getUserInformationsSuccess(state, action) {
      state.isFetchingUserInformations = false;
      const { birth, country, email, firstName, lastName, identifier } = action.payload;
      state.birth = birth || '';
      state.country = country || '';
      state.email = email;
      state.firstName = firstName || '';
      state.lastName = lastName || '';
      state.userId = identifier;
      state.hasFetchedInitialUserInformation = true;
    },
    getUserInformationsFailure(state) {
      state.isFetchingUserInformations = false;
      state.hasFetchedInitialUserInformation = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function userInformationsFetchRequest() {
  return async (dispatch, getState) => {
    const {
      language: { language },
      company: { company },
      user: { email },
    } = getState();

    // We only fetch user information if there is nothing in the email field
    // In case we need to force a user information fetch request, we can easily do so
    // by providing an argument to this function and adding it into the conditional
    // statement
    if (!email) {
      dispatch(getUserInformationsRequest());
      return API.get('/user', {
        headers: {
          Authorization: `Bearer ${getState().authentication.token}`,
        },
      })
        .then((response) => {
          const { data } = response;
          dispatch(getUserInformationsSuccess(data));

          // We don't have the reason to store language in the state yet since we
          // only need to set it once on app load here
          // This piece of code just makes sure that the same language is used
          // across all the user's devices and sessions
          const { languageCode: lng } = data;
          if (language !== lng) {
            i18n.changeLanguage(lng);
            dispatch(languageChangeRequest());
            dispatch(languageChangeSuccess({ company, language: lng }));
          }
        })
        .catch(() => {
          const networkError = i18n.t('network_error');
          dispatch(getUserInformationsFailure({ reason: networkError }));
          dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        });
    }
    return 0;
  };
}

export const {
  getUserInformationsFailure,
  getUserInformationsRequest,
  getUserInformationsSuccess,
  updateUserPersonalInformationsFailure,
  updateUserPersonalInformationsRequest,
  updateUserPersonalInformationsSuccess,
} = userSlice.actions;
export default userSlice.reducer;
