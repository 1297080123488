/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import { createNotificationByType, NOTIFICATION_TYPES } from 'utils/notification';
import API from 'utils/api';
import { RESET_STATE } from './sharedActions';

const initialState = {
  selectedCurrency: { blocksquare: 'EUR' },
  daiToFiatRates: {},
  usdToOtherRates: {},
  isFetchingRate: false,
  isUpdating: false,
  isFetching: false,
  updateErrorMessage: null,
};

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    rateFetchRequest(state) {
      state.isFetchingRate = true;
    },
    rateFetchSuccess(state, action) {
      const { daiToFiatRates, usdToOtherRates } = action.payload;
      state.isFetchingRate = false;
      state.daiToFiatRates = daiToFiatRates;
      state.usdToOtherRates = usdToOtherRates;
    },
    rateFetchFailure(state) {
      state.isFetchingRate = false;
    },
    changeCurrencyRequest(state) {
      state.isUpdating = true;
    },
    currencyChangeSuccess(state, action) {
      const { company, currency } = action.payload;
      state.selectedCurrency[company] = currency;
      state.isUpdating = false;
      state.updateErrorMessage = null;
    },
    currencyChangeFailure(state, action) {
      const { reason } = action.payload;
      state.isUpdating = false;
      state.updateErrorMessage = reason;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, (state) => {
      const newState = { ...initialState };
      newState.selectedCurrency = state.selectedCurrency;
      return newState;
    });
  },
});

export function getExchangeRate() {
  return async (dispatch, getState) => {
    dispatch(rateFetchRequest());

    try {
      const [firstRes, secondRes] = await Promise.all([
        API.get(`/info/exchange-rate`, {
          headers: {
            Authorization: `Bearer ${getState().authentication.token}`,
          },
        }),
        API.get(`/info/coins-value`),
      ]);

      if (firstRes.data && secondRes.data) {
        const { data: usdToOtherRates } = firstRes;
        const {
          data: { DAI: daiToFiatRates },
        } = secondRes;

        // For the USD to other fiat currencies - we don't receive USD exchange rate
        // from the back-end since it's unnecessary ( 1USD === 1USD ),
        // but I have to set it here for the rest of the front-end to continue working normally when
        // deriving fiat values if user's settings already state that the preferred currency is USD
        usdToOtherRates.USD = 1;
        dispatch(rateFetchSuccess({ daiToFiatRates, usdToOtherRates }));
      } else {
        throw new Error("We didn't receive exchange rate data");
      }
    } catch (error) {
      dispatch(rateFetchFailure(error));
    }
  };
}

export function changeCurrency(company, currency) {
  return async (dispatch) => {
    dispatch(changeCurrencyRequest());
    dispatch(currencyChangeSuccess({ company, currency }));
    dispatch(createNotificationByType(NOTIFICATION_TYPES.SETTINGS_SAVED));
  };
}

export const {
  changeCurrencyRequest,
  currencyChangeSuccess,
  currencyChangeFailure,
  rateFetchRequest,
  rateFetchSuccess,
  rateFetchFailure,
} = currencySlice.actions;

export default currencySlice.reducer;
