import { createTheme } from '@material-ui/core/styles';

import breakpoints from './breakpoints';
import typography from './typography';
import overrides from './overrides';
import props from './props';
import shadows from './shadows';

const convertHexToRGBA = (hex, opacity) => {
  const tempHex = hex.replace('#', '');
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

export default function generateTheme(identifier, brandColor) {
  const theme = {
    palette: {
      background: {
        default: 'rgba(0, 192, 174, 0.3)',
      },
      primary: {
        main: '#00C0AE',
        contrastText: '#FFF',
      },
      secondary: {
        main: '#FFF',
        contrastText: '#00C0AE',
      },
    },
    sidebar: {
      color: '#000',
      background: '#fff',
      connected: '#00C0AE',
      not_connected: '#D02E5A',
    },
    body: {
      primary: '#00C0AE',
      secondary: '#fff',
    },
  };
  // Override base theme with company colors
  theme.palette.background.default = convertHexToRGBA(brandColor, 0.3);
  theme.palette.primary.main = brandColor;
  theme.palette.secondary.contrastText = brandColor;
  theme.body.primary = brandColor;
  theme.sidebar.connected = brandColor;

  return createTheme(
    {
      spacing: 4,
      breakpoints,
      overrides,
      props,
      typography,
      shadows,
      palette: theme.palette,
      body: theme.body,
      sidebar: theme.sidebar,
    },
    { id: identifier }
  );
}

export const fallbackTheme = generateTheme('fallbackTheme', '#adbab8');
