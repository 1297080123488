export function displayHexString(address, length = 6) {
  const addressLength = address.length;

  const firstPart = address.substring(0, length);
  const lastPart = address.substring(addressLength - length, addressLength);
  return `${firstPart}...${lastPart}`;
}

export function getCurrentValuation(tokenValuation, propertyValuation, totalSupply) {
  return (tokenValuation * totalSupply + propertyValuation * (100000 - totalSupply)) / 100000;
}

export function getCurrentPropertyUserTokenValuation(
  tokenValuation,
  propertyValuation,
  totalSupply,
  userTokenAmount
) {
  const currentPropertyValue = getCurrentValuation(tokenValuation, propertyValuation, totalSupply);
  return (currentPropertyValue / 100000) * userTokenAmount;
}
