/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n';
import API, { STATUS_SUCCESS } from 'utils/api';
import {
  createNotification,
  createNotificationByType,
  NOTIFICATION_TYPES,
} from 'utils/notification';
import { RESET_STATE } from './sharedActions';

const initialState = {
  isInitialState: true,
  isAccredited: false,
  isRequestingKYCStatus: false,
  hasFetchedInitialKYCStatus: false,
  kycStatus: 'NEW',
  step: 0,
  remainingAttempts: 0,
  errorMessage: null,
  kycUrl: '',
  isRequestingKycUrl: false,
  kycUrlErrorMessage: null,
};

const userKycSlice = createSlice({
  name: 'userKyc',
  initialState,
  reducers: {
    kycStatusFetchRequest(state) {
      state.isRequestingKYCStatus = true;
    },
    kycStatusFetchSuccess(state, action) {
      const { isAccredited, kycStatusEnum, remainingAttempts } = action.payload;
      state.errorMessage = null;
      state.isAccredited = isAccredited;
      state.kycStatus = kycStatusEnum;
      state.remainingAttempts = remainingAttempts;
      state.isRequestingKYCStatus = false;
      state.hasFetchedInitialKYCStatus = true;
    },
    kycStatusFetchFailure(state, action) {
      const { reason } = action.payload;
      state.errorMessage = reason;
      state.isRequestingKYCStatus = false;
      state.hasFetchedInitialKYCStatus = true;
    },
    kycUrlRequest(state) {
      state.isRequestingKycUrl = true;
    },
    kycUrlSuccess(state, action) {
      const { reason } = action.payload;
      state.kycUrl = reason;
      state.kycUrlErrorMessage = null;
      state.isRequestingKycUrl = false;
    },
    kycUrlFailure(state, action) {
      const { message } = action.payload;
      state.isRequestingKycUrl = false;
      state.kycUrlErrorMessage = message;
    },
    incrementKycStep(state) {
      state.step += 1;
    },
    decrementKycStep(state) {
      state.step -= 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

// possible kyc status fetch failure reasons:

// kyc_done
// kyc_couldnt_start
// kyc_too_many_failed

export function userKycStatusFetchRequest(refresh = false) {
  return async (dispatch, getState) => {
    const state = getState();
    const { userKyc } = state;
    if (userKyc.isInitialState || refresh) {
      dispatch(kycStatusFetchRequest());
      return API.get('/account/kyc', {
        headers: {
          Authorization: `Bearer ${getState().authentication.token}`,
        },
      })
        .then((response) => {
          const { data } = response;
          dispatch(kycStatusFetchSuccess(data));
        })
        .catch(() => {
          const networkError = i18n.t('network_error');
          dispatch(kycStatusFetchFailure({ reason: networkError }));
          dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        });
    }
    return null;
  };
}

// possible kyc url getting failure reasons:

// kyc_generic_error
// kyc_too_many_failed
// kyc_couldnt_process
// kyc_selfie_missmatch
// kyc_failed
// kyc_too_young

export function getKYCUrl() {
  return async (dispatch, getState) => {
    dispatch(kycUrlRequest());
    return API.post(
      '/user/kyc',
      {},
      {
        headers: {
          Authorization: `Bearer ${getState().authentication.token}`,
        },
      }
    )
      .then((response) => {
        const { data } = response;
        const { reason, status } = data;
        if (status === STATUS_SUCCESS) {
          dispatch(kycUrlSuccess(data));
        } else {
          dispatch(kycUrlFailure({ message: i18n.t(reason) }));
          dispatch(createNotification(i18n.t(reason), 'error'));
        }
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(kycUrlFailure({ message: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export const {
  kycStatusFetchRequest,
  kycStatusFetchSuccess,
  kycStatusFetchFailure,
  incrementKycStep,
  decrementKycStep,
  kycUrlSuccess,
  kycUrlRequest,
  kycUrlFailure,
} = userKycSlice.actions;
export default userKycSlice.reducer;
