import { createSlice } from '@reduxjs/toolkit';
import { createNotification } from 'utils/notification';
import { gqlclient, GET_PORTFOLIO_QUERY } from 'query';
import { getCurrentPropertyUserTokenValuation } from 'utils/format';
import { RESET_STATE } from './sharedActions';
import { formatBigInt } from 'utils';

const initialState = {
  properties: [],
  valuation: 0,
  pendingRevenue: 0,
  isRequestingProperties: false,
  errorMessageProperties: null,
};

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    portfolioPropertiesFetchRequest(state) {
      state.isRequestingProperties = true;
    },
    portfolioPropertiesFetchSuccess(state, action) {
      const { properties, valuation, pendingRevenue } = action.payload;
      state.properties = properties;
      state.valuation = valuation;
      state.pendingRevenue = pendingRevenue;
      state.isRequestingProperties = false;
      state.errorMessageProperties = null;
    },
    portfolioPropertiesFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingProperties = false;
      state.errorMessageProperties = reason;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function getPortfolioOnChainProperties(userId) {
  return async (dispatch, getState) => {
    dispatch(portfolioPropertiesFetchRequest());
    return gqlclient
      .query({
        query: GET_PORTFOLIO_QUERY,
        variables: {
          userId: userId.toLowerCase(),
        },
      })
      .then((result) => {
        const {
          data: { user },
        } = result;
        let totalPendingRevenue = 0;
        const tempProperties = [];
        if (user) {
          const { wallets, pendingRents } = user;

          const tempPendingRevenues = new Array(pendingRents.length);
          const globalCpBytes = getState().company.cpBytes;

          if (pendingRents.length > 0) {
            pendingRents.forEach((userRent) => {
              const {
                property: {
                  id,
                  cp: { id: cpBytes },
                },
                pendingRent,
              } = userRent;

              // Skip if cpBytes of property don't match current company cpBytes
              if (cpBytes !== globalCpBytes) return;

              const tempRevenue = formatBigInt(BigInt(pendingRent));
              totalPendingRevenue += tempRevenue;

              const data = {};
              data.revenue = tempRevenue;

              tempPendingRevenues[id] = data;
            });
          }

          wallets.forEach((tempWallet) => {
            const { properties } = tempWallet;

            properties.forEach((propertyUser) => {
              const {
                amount,
                property: {
                  id,
                  tokenValuation,
                  propertyValuation,
                  token: { totalSupply, name, symbol },
                  cp: { id: cpBytes },
                },
              } = propertyUser;

              // Skip if cpBytes of property don't match current company cpBytes
              if (cpBytes !== globalCpBytes) return;

              const tempPropertyIndex = tempProperties.findIndex((e) => e.id === id);
              const tempPropertyAmount =
                tempPropertyIndex >= 0 ? tempProperties[tempPropertyIndex].amount : 0;

              const tempAmount = formatBigInt(BigInt(amount)) + tempPropertyAmount;
              const tempTokenValuation = formatBigInt(BigInt(tokenValuation));
              const tempPropertyValuation = formatBigInt(BigInt(propertyValuation));
              const tempTotalSupply = formatBigInt(BigInt(totalSupply));

              const valuation = getCurrentPropertyUserTokenValuation(
                tempTokenValuation,
                tempPropertyValuation,
                tempTotalSupply,
                tempAmount
              );

              const tempRevenue = tempPendingRevenues[id] ? tempPendingRevenues[id].revenue : 0;

              const data = {};
              data.id = id;
              data.amount = tempAmount;
              data.valuation = valuation;
              data.companyId = cpBytes;
              data.revenue = tempRevenue;
              data.name = name;
              data.symbol = symbol;

              if (tempPropertyIndex >= 0) {
                tempProperties[tempPropertyIndex] = data;
              } else {
                tempProperties.push(data);
              }
            });
          });
        }

        // Get the total valuation of all properties in the portfolio
        const totalValuation =
          tempProperties.length > 0
            ? tempProperties.map((e) => e.valuation).reduce((prev, next) => prev + next)
            : 0;

        dispatch(
          portfolioPropertiesFetchSuccess({
            properties: tempProperties,
            valuation: totalValuation,
            pendingRevenue: totalPendingRevenue,
          })
        );
      })
      .catch((error) => {
        dispatch(portfolioPropertiesFetchFailure({ reason: error.message }));
        dispatch(createNotification(error.message, 'error'));
      });
  };
}

export const {
  portfolioPropertiesFetchFailure,
  portfolioPropertiesFetchRequest,
  portfolioPropertiesFetchSuccess,
} = portfolioSlice.actions;
export default portfolioSlice.reducer;
