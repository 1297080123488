export const configFetchErrorTypes = {
  invalidData: 'invalidData',
  serverError: 'serverError',
} as const;

export type CompanyState = {
  defaultCompanyRoute: string;
  company: string;
  cpBytes: string;
  policy: string;
  terms: string;
  languages: Array<any>;
  offeringCurrency: string;
  configuration: {
    title: null;
    images: any;
    theme: { identifier: string; brandColor: string } | null;
  };
  companyConfigurationFetchError: boolean;
  companyConfigurationFetchErrorType: string;
  defaultCompanyRouteFetchError: boolean;
  defaultCompanyRouteNotFound: boolean;
  isFetchingDefaultCompany: boolean;
  isUpdatingCompany: boolean;
  updateCompanyErrorMessage: string | null;
  isRequestingOnChainData: boolean;
  onChainRequestErrorMessage: null;
  isRequestingLanguages: boolean;
  languagesRequestError: string | null;
  isRequestingTermsAndPolicy: boolean;
  errorRequestingTermsAndPolicy: string | null;
  isRequestingCurrency: boolean;
  errorRequestingCurrency: string | null;
  isRequestingCompanyDetails: boolean;
  errorRequestingCompanyDetails: string | null;
};
