import { ERC20Abi } from 'contracts';
import type { TransactionResponse } from 'ethers';
import { Contract } from 'ethers';
import { UNLIMITED_ALLOWANCE_IN_WEI_BIG_INT_TO_SET } from 'utils/constants';
import { getProvider } from './helper';

/**
 * Get the token balance of a given user
 *
 *
 * @param contractAddress - The contract address that we check allowance on
 * @param userAddress - The token owner that we check the balance for
 * @returns Return the amount of tokens the user wallet owns
 *
 */
export const getTokenBalance = async (
  contractAddress: string,
  userAddress: string
): Promise<bigint> => {
  const provider = getProvider();
  const tokenContract = new Contract(contractAddress, ERC20Abi, provider);
  const tokenBalance = (await tokenContract.balanceOf(userAddress)) as bigint;
  return tokenBalance;
};

/**
 * Get the token allowance of a given spender for a given user
 *
 *
 * @param contractAddress - The contract address that we check allowance on
 * @param userAddress - The token owner that we check the spender allownace against
 * @param spenderAddress - The spender address that that we check the allowance for
 * @returns Return the amount of allowed allowance
 *
 */
export const getTokenAllowance = async (
  contractAddress: string,
  userAddress: string,
  spenderAddress: string
): Promise<bigint> => {
  const provider = getProvider();
  const tokenContract = new Contract(contractAddress, ERC20Abi, provider);
  const tokenAllowance = (await tokenContract.allowance(userAddress, spenderAddress)) as bigint;
  return tokenAllowance;
};

/**
 * Sets token allowance and returns the transaction response
 *
 *
 * @param contractAddress - The contract address that we call approve on
 * @param spenderAddress - The spender address that is allowed to spend the tokens
 * @param tokenAllowance - The amount the spender address is allowed to spend
 * @returns Return transactionRepsonse object for further use
 *
 */
export const setTokenAllowanceRaw = async (
  contractAddress: string,
  spenderAddress: string,
  tokenAllowance: bigint = UNLIMITED_ALLOWANCE_IN_WEI_BIG_INT_TO_SET
): Promise<TransactionResponse> => {
  const provider = getProvider();
  const signer = await provider.getSigner();
  const tokenContract = new Contract(contractAddress, ERC20Abi, signer);
  return await tokenContract.approve(spenderAddress, tokenAllowance);
};

/**
 * Sets token allowance and returns if it was successful.
 *
 *
 * @param contractAddress - The contract address that we call approve on
 * @param spenderAddress - The spender address that is allowed to spend the tokens
 * @param tokenAllowance - The amount the spender address is allowed to spend
 * @returns Return if the transaction was successful
 *
 */
export const setTokenAllowance = async (
  contractAddress: string,
  spenderAddress: string,
  tokenAllowance: bigint = UNLIMITED_ALLOWANCE_IN_WEI_BIG_INT_TO_SET
): Promise<boolean> => {
  const transaction = await setTokenAllowanceRaw(contractAddress, spenderAddress, tokenAllowance);
  const transactionReceipt = await transaction.wait();
  return transactionReceipt?.status === 1;
};
