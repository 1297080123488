import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RESET_STATE } from './sharedActions';
import type { DialogKeys, DialogsState } from 'types';

const initialState: DialogsState = {
  activeDialog: null,
  activeDialogData: {},
};

const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    showDialog(state, { payload }: PayloadAction<{ type: DialogKeys; data?: any }>) {
      const { type, data } = payload;
      state.activeDialog = type;
      state.activeDialogData = data;
    },
    hideDialogs(state) {
      state.activeDialog = null;
      state.activeDialogData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export const { hideDialogs, showDialog } = dialogsSlice.actions;
export default dialogsSlice.reducer;
