import type { FC } from 'react';
import { memo } from 'react';
import styled from 'styled-components';

import { CircularProgress as MuiCircularProgress } from '@material-ui/core';
import { spacing } from '@material-ui/system';

const CircularProgress = styled(MuiCircularProgress)(spacing);

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  min-width: 100%;
`;

const centeredLoaderStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

type LoaderProps = {
  size?: string | number;
  color?: 'primary' | 'secondary' | 'inherit';
  style?: object;
  centerLoader?: object;
};

const Loader: FC<LoaderProps> = ({ color = 'primary', size = 40, style, centerLoader }) => {
  let finalStyle = {};

  if (style) {
    finalStyle = { ...style };
  }

  if (centerLoader) {
    finalStyle = { ...finalStyle, ...centeredLoaderStyle };
  }

  return (
    <Root style={finalStyle}>
      <CircularProgress size={size} m={2} color={color} />
    </Root>
  );
};

export default memo(Loader);
